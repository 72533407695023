import React from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import { ArrowUpRight } from "./components/Svgs";
import { motion } from "framer-motion";

function App() {
  return (
    <div className="App min-h-screen w-screen bg-gradient-to-r flex flex-col items-center justify-center">
      <Header />
      <div className="h-screen w-screen grid place-content-center  relative  z-0">
        <img
          src="/Bgimg.jpg"
          alt="Background thing"
          className="absolute top-0 h-screen bg-contain object-cover object-top  w-screen "
        />
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.2, ease: "easeInOut" }}
          className="w-[800px] bg-black/85 backdrop-blur  z-10 text-5xl smartphone:text-2xl px-4 py-8 text-center text-white font-semibold tracking-wider "
        >
          Manage Explore Discover
          <h2 className="w-full mt-4 text-xl smartphone:text-lg  text-center text-white  ">
            All in One Place
          </h2>
        </motion.div>

        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 1, duration: 0.2, ease: "easeInOut" }}
          className="w-fit smartphone:w-full flex gap-4  justify-center smartphone:justify-between   absolute bottom-0 pl-10 py-5 pr-5  bg-black/85 backdrop-blur overflow-hidden"
        >
          <div className="flex flex-col justify-center  text-white text-xl smartphone:text-base">
            <h1 className="text-3xl smartphone:text-xl  font-semibold ">
              Access Our dashboards
            </h1>
            <h2>You are?</h2>
          </div>

          <ul className="flex flex-col  ">
            <li className="group">
              <Link to="/tenant-dashboard">
                Tenant{" "}
                <ArrowUpRight className=" w-6  transition-all group-hover:translate-x-1 group-hover:-translate-y-1 " />
              </Link>
            </li>
            <li className="group">
              <Link to="/landlord-dashboard">
                Landlord{" "}
                <ArrowUpRight className=" w-6  transition-all group-hover:translate-x-1 group-hover:-translate-y-1 " />
              </Link>
            </li>
            <li className="group">
              <Link to="/contractor-dashboard">
                Contractor{" "}
                <ArrowUpRight className=" w-6  transition-all group-hover:translate-x-1 group-hover:-translate-y-1 " />
              </Link>
            </li>
          </ul>
        </motion.div>
      </div>
    </div>
  );
}

export default App;
