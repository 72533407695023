import { AnimatePresence, motion } from "framer-motion";
import formatDate from "../../../../helpers/formatDate";
import { toast, ToastContainer } from "react-toastify";
import { ArrowUpRight, Spinner } from "../../../../components/Svgs";
import { Link } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase/firebaseConfig";
import { useEffect, useMemo, useState } from "react";

export default function Bids() {
  const [pendingMaintenanceRequests, setPendingMaintenanceRequests] =
    useState(null);
  const [paidMaintenanceRequests, setPaidMaintenanceRequests] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isTicketExpanded, setIsTicketExpanded] = useState(null);
  const user = useMemo(() => auth.currentUser, []);

  useEffect(() => {
    if (!user) return;

    const fetchMaintenanceRequests = async () => {
      try {
        setIsLoading(true); // Start loading
        const landlordRef = doc(db, "users", user.uid); // Get landlord's document reference
        const maintenanceRequestsRef = collection(db, "maintenanceRequests");

        const maintenanceRequestsQuery = query(
          maintenanceRequestsRef,
          where("issuedTo", "==", landlordRef)
        );
        const querySnapshot = await getDocs(maintenanceRequestsQuery);

        const maintenanceRequests = [];
        for (const maintenanceRequestsDoc of querySnapshot.docs) {
          const maintenanceRequestsData = {
            id: maintenanceRequestsDoc.id,
            ...maintenanceRequestsDoc.data(),
            tenantInfo: null,
            landlordInfo: null,
          };
          if (maintenanceRequestsData.issuedTo) {
            const landlordRef = maintenanceRequestsData.issuedTo; // Assuming landlordId is a Firestore reference
            const landlord = await getDoc(landlordRef);
            if (landlord.exists()) {
              maintenanceRequestsData.landlordInfo = {
                ...landlord.data(),
                landlordName: `${landlord.data().firstName} ${
                  landlord.data().lastName
                }`,
              }; // Add landlord data to rent object
            } else {
              console.warn(
                `Landlord document ${landlordRef.id} does not exist.`
              );
            }
          }
          if (maintenanceRequestsData.issuedBy) {
            const tenantDocRef = maintenanceRequestsData.issuedBy; // Assuming tenantId is a Firestore reference
            const tenantDoc = await getDoc(tenantDocRef);
            if (tenantDoc.exists()) {
              maintenanceRequestsData.tenantInfo = {
                ...tenantDoc.data(),
                tenantName: `${tenantDoc.data().firstName} ${
                  tenantDoc.data().lastName
                }`,
              }; // Add Tenant data to rent object
            } else {
              console.warn(
                `Tenant document ${tenantDocRef.id} does not exist.`
              );
            }
          }
          maintenanceRequests.push(maintenanceRequestsData);
        }
        console.log(maintenanceRequests);
        // Sort maintenanceRequests into active (pending) and past (paid) based on status
        const pending = maintenanceRequests.filter(
          (req) => req.isBidding === true
        );
        const Resolved = maintenanceRequests.filter(
          (req) => req.isBidding === false
        );

        setPaidMaintenanceRequests(Resolved);
        setPendingMaintenanceRequests(pending);
      } catch (error) {
        console.error("Error fetching MaintenanceRequests:", error);
        toast.error("Failed to fetch MaintenanceRequests. Please try again.");
      } finally {
        setIsLoading(false); // Stop loading
      }
    };
    fetchMaintenanceRequests();
  }, [user]);

  if (isLoading) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <Spinner className=" animate-spin ease-in-out mx-auto  dark:text-white text-black " />
      </div>
    );
  }

  if (!pendingMaintenanceRequests || !paidMaintenanceRequests) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p>Error loading data. Please refresh the page.</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="w-full min-h-screen  flex flex-col  overflow-y-auto"
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AnimatePresence mode="wait">
        {isTicketExpanded && (
          <TicketDetails
            ticket={isTicketExpanded}
            setIsTicketExpanded={setIsTicketExpanded}
          />
        )}
      </AnimatePresence>
      {/* Pending MaintenanceRequests */}
      <div key="PendingMaintenanceRequests" className="w-full pt-2">
        <div className="w-full h-fit dark:bg-white/10 bg-black/10 dark:text-white text-black px-5 py-1 mb-2 flex items-center justify-between text-[150%] rounded-xl ">
          Ongoing Bids
        </div>
        <ul className="w-full smartphone:justify-center flex flex-wrap gap-2 px-4">
          {pendingMaintenanceRequests.map((req) => (
            <li
              key={req.id}
              className="w-80 smartphone:w-full bg-black/5 dark:bg-white/20 text-black dark:text-white  p-4 rounded-md"
            >
              <div className="text-[110%] flex flex-col justify-between ">
                <span>
                  <h1>Issue</h1>
                  <h3 className="text-[80%]">{req.issueType}</h3>
                </span>
                <span>
                  <h1>Ticket Created On</h1>
                  <h3 className="text-[80%]">
                    {formatDate(req.requestedDate).inString}
                  </h3>
                </span>
                <span className="w-full place-self-end  flex justify-between mt-3 text-xs">
                  <button
                    onClick={() => setIsTicketExpanded(req)}
                    className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 "
                  >
                    Details
                  </button>
                  <button className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 ">
                    <Link
                      to={`/ticket/bids/${req.id}`}
                      className="w-full h-full"
                    >
                      Bids
                    </Link>
                  </button>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Paid MaintenanceRequests */}
      <div key="ResolvedMaintenanceRequests" className="w-full">
        <div className="w-full h-fit dark:bg-white/10 bg-black/10 dark:text-white text-black px-5 py-1 my-2 mt-6 flex items-center justify-between text-[150%] rounded-xl ">
          <h1>Locked Bids</h1>
        </div>
        <ul className="w-full smartphone:justify-center flex flex-wrap gap-4 px-4">
          {paidMaintenanceRequests.map((req) => (
            <li
              key={req.id}
              className="w-80 smartphone:w-full bg-black/5 dark:bg-white/20 text-black dark:text-white  p-4 rounded-md"
            >
              <div className="text-[110%] flex flex-col gap-2">
                <span>
                  <h1>Issue</h1>
                  <h3 className="text-[80%]">{req.issueType}</h3>
                </span>
                <span>
                  <h1>Ticket Created On</h1>
                  <h3 className="text-[80%]">
                    {formatDate(req.requestedDate).inString}
                  </h3>
                </span>
                <span className="w-full place-self-end  flex justify-between mt-3 text-xs">
                  <button
                    onClick={() => setIsTicketExpanded(req)}
                    className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 "
                  >
                    Details
                  </button>
                  <button className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 ">
                    <Link to={`/ticket/bids/${req.id}`}>Bids</Link>
                  </button>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
}
const TicketDetails = ({ ticket, setIsTicketExpanded }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="w-screen h-screen fixed top-0 left-0 z-50 py-4 backdrop-blur bg-black/20 flex justify-center items-start"
    >
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-4xl  w-full h-full  overflow-y-auto">
        {/* Header */}
        <div className="w-full flex items-center justify-start mb-6">
          <button
            onClick={() => setIsTicketExpanded(null)}
            className="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 group"
          >
            <ArrowUpRight className="w-4 rotate-[-135deg] group-hover:-translate-x-[1px] active:translate-x-0 duration-100 transition-all" />
          </button>
          <h2 className="text-2xl font-bold text-gray-800">
            Maintenance Request
          </h2>
        </div>

        {/* Row 1: Two-column layout for Key Details */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <h3 className="text-sm font-medium text-gray-500">Ticket ID</h3>
            <p className="text-lg font-semibold text-gray-800 overflow-x-auto">
              {ticket.id || "N/A"}
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <h3 className="text-sm font-medium text-gray-500">Status</h3>
            <p
              className={`text-lg font-semibold ${
                ticket.status === "Completed"
                  ? "text-green-600"
                  : "text-orange-500"
              }`}
            >
              {ticket.status || "Pending"}
            </p>
          </div>
        </div>

        {/* Row 2: Single-column layout for additional info */}
        <div className="space-y-3">
          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">Issue</h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.issueType || "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">
              Requested Date
            </h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.requestedDate
                ? formatDate(ticket.requestedDate).inString
                : "Pending"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">Issued By</h3>
            <p className="text-gray-800">
              {`${ticket.tenantInfo.tenantName || "N/A"} (${
                ticket.tenantInfo.email
              })`}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">Fees</h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.fees ? `$${ticket.fees}` : "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">
              Completion Date
            </h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.completionDate
                ? ticket.completionDate.toDate().toLocaleDateString()
                : "N/A"}
            </p>
          </div>
        </div>

        {/* Row 3: Full-width single-column layout for description */}
        <div className="p-4 bg-gray-50 rounded-lg shadow-md mt-6">
          <h3 className="text-sm font-medium text-gray-500">
            Issue Description
          </h3>
          <p className="text-gray-800">{ticket.issueDescription || "N/A"}</p>
        </div>
      </div>
    </motion.div>
  );
};
