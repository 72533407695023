import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore"; // Firebase Firestore imports
import { db } from "../../firebase/firebaseConfig";
import { AnimatePresence, motion } from "framer-motion";
import formatDate from "../../helpers/formatDate";
import { Card, Globe } from "../../components/Svgs";

export default function PaymentGateway() {
  const { id } = useParams();
  const [paymentMethod, setPaymentMethod] = useState("methodSelection");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [rentDetails, setRentDetails] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [error, setError] = useState(null);

  // Fetch rent and property details
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const rentDocRef = doc(db, "rents", id);
        const rentDoc = await getDoc(rentDocRef);

        if (rentDoc.exists()) {
          const rentData = rentDoc.data();
          setRentDetails(rentData);

          // Fetch property details
          if (rentData.propertyId) {
            const propertyDoc = await getDoc(rentData.propertyId);

            if (propertyDoc.exists()) {
              setPropertyDetails(propertyDoc.data());
            } else {
              setError("Property document does not exist.");
            }
          }
        } else {
          setError("Rent document does not exist.");
        }
      } catch (err) {
        setError("Error fetching details: " + err.message);
      }
    };

    fetchDetails();
  }, [id]);

  // Render dynamic sections
  const renderDynamicSection = () => {
    switch (paymentMethod) {
      case "Credit Card":
        return (
          <CreditCard
            id={id}
            method="Credit Card"
            setPaymentStatus={setPaymentStatus}
          />
        );
      case "Debit Card":
        return (
          <DebitCard
            id={id}
            method="Debit Card"
            setPaymentStatus={setPaymentStatus}
          />
        );
      case "Net Banking":
        return <div>Net Banking (Coming Soon!)</div>;
      default:
        return (
          <MethodSelection
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-1/2 flex flex-wrap   bg-white p-6 rounded-lg shadow-md">
        <div id="topBar" className="w-full border-b border-black mb-6">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Payment Gateway
          </h1>
          <p className="text-sm text-gray-600 mb-2">
            Bill ID: <span className="font-semibold">{id}</span>
          </p>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div id="leftSec" className="w-1/3">
          {rentDetails && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-700">
                Rent Details:
              </h2>
              <p>Due Amount: ${rentDetails.amountDue}</p>
              <p>Created On: {formatDate(rentDetails.createdAt).inString}</p>
            </div>
          )}
          {propertyDetails && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-700">
                Property Details:
              </h2>
              <p>Address: {propertyDetails.address}</p>
              <p>Owner: {propertyDetails.landlord?.email}</p>
            </div>
          )}
        </div>
        <div id="rightSec" className="w-2/3">
          <AnimatePresence mode="wait">
            {renderDynamicSection()}
          </AnimatePresence>
        </div>

        {paymentStatus === "success" && (
          <p className="w-full mt-4 text-green-500 text-center font-semibold">
            Payment Successful! 🎉
          </p>
        )}
        {paymentStatus === "failed" && (
          <p className="w-full mt-4 text-red-500 text-center font-semibold">
            Payment Failed! Please fill all details correctly.
          </p>
        )}
      </div>
    </div>
  );
}

const CreditCard = ({ method, id, setPaymentStatus }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      if (cardNumber && expiry && cvv) {
        setLoading(true);
        const rentDocRef = doc(db, "rents", id);
        await updateDoc(rentDocRef, {
          paymentStatus: "Paid",
          paymentMethod: method,
          updatedAt: Timestamp.now(),
          paymentDate: Timestamp.now(),
        });
        setPaymentStatus("success");
        setLoading(false);
        setTimeout(() => {
          navigate("/tenant-dashboard");
        }, 1500);
      } else {
        setPaymentStatus("failed");
      }
    } catch (err) {
      setPaymentStatus("failed");
    }
  };
  return (
    <motion.div initial={{ x: -20 }} animate={{ x: 0 }} exit={{ x: -20 }}>
      <form onSubmit={handlePayment}>
        <div className="mb-4">
          <label htmlFor="cardNumber" className="block text-gray-700 mb-1">
            Card Number
          </label>
          <input
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="1234 5678 9012 3456"
            maxLength="19"
          />
        </div>
        <div className="mb-4 flex gap-4">
          <div className="w-1/2">
            <label htmlFor="expiry" className="block text-gray-700 mb-1">
              Expiry Date
            </label>
            <input
              type="text"
              id="expiry"
              value={expiry}
              onChange={(e) => setExpiry(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="MM/YY"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="cvv" className="block text-gray-700 mb-1">
              CVV
            </label>
            <input
              type="text"
              id="cvv"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="123"
              maxLength="3"
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="paymentMethod" className="block text-gray-700 mb-1">
            Payment Method
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none"
        >
          {loading ? "Processing payment" : "Pay Now"}
        </button>
      </form>
    </motion.div>
  );
};
const DebitCard = ({ method, id, setPaymentStatus }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      if (cardNumber && expiry && cvv) {
        setLoading(true);
        const rentDocRef = doc(db, "rents", id);
        await updateDoc(rentDocRef, {
          paymentStatus: "Paid",
          paymentMethod: method,
          updatedAt: Timestamp.now(),
          paymentDate: Timestamp.now(),
        });
        setPaymentStatus("success");
        setLoading(false);
        setTimeout(() => {
          navigate("/tenant-dashboard");
        }, 1500);
      } else {
        setPaymentStatus("failed");
      }
    } catch (err) {
      setPaymentStatus("failed");
    }
  };
  return (
    <motion.div initial={{ x: -20 }} animate={{ x: 0 }} exit={{ x: -20 }}>
      <form onSubmit={handlePayment}>
        <div className="mb-4">
          <label htmlFor="cardNumber" className="block text-gray-700 mb-1">
            Card Number
          </label>
          <input
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="1234 5678 9012 3456"
            maxLength="19"
          />
        </div>
        <div className="mb-4 flex gap-4">
          <div className="w-1/2">
            <label htmlFor="expiry" className="block text-gray-700 mb-1">
              Expiry Date
            </label>
            <input
              type="text"
              id="expiry"
              value={expiry}
              onChange={(e) => setExpiry(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="MM/YY"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="cvv" className="block text-gray-700 mb-1">
              CVV
            </label>
            <input
              type="text"
              id="cvv"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="123"
              maxLength="3"
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="paymentMethod" className="block text-gray-700 mb-1">
            Payment Method
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none"
        >
          {loading ? "Processing payment" : "Pay Now"}
        </button>
      </form>
    </motion.div>
  );
};
const MethodSelection = ({ paymentMethod, setPaymentMethod }) => {
  const methods = [
    {
      name: "Credit Card",
      icon: <Card className={"w-20"} />,
    },
    {
      name: "Debit Card",
      icon: <Card className={"w-20"} />,
    },
    {
      name: "Net Banking",
      icon: <Globe className={"w-20"} />,
    },
  ];
  return (
    <ul className="flex flex-wrap gap-3 justify-center">
      {methods.map((method) => {
        return (
          <li
            key={method.name}
            className="w-32 p-4 cursor-pointer border border-black/20 rounded-lg flex flex-col justify-between items-center"
            onClick={() => setPaymentMethod(method.name)}
          >
            <div>{method.icon}</div>
            <h1>{method.name}</h1>
          </li>
        );
      })}
    </ul>
  );
};
