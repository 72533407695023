import { useEffect, useMemo, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  serverTimestamp,
  doc,
  getDoc,
} from "firebase/firestore";
import { motion } from "framer-motion";
import { Spinner } from "../../../../components/Svgs";
import { auth, db } from "../../../../firebase/firebaseConfig";
import { toast, ToastContainer } from "react-toastify";

const formatDate = (timestamp) => {
  if (!timestamp || !timestamp.seconds) {
    return "N/A";
  }
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleDateString();
};

export default function TicketList() {
  const [pendingTickets, setPendingTickets] = useState([]);
  const [resolvedTickets, setResolvedTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bidAmount, setBidAmount] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const user = useMemo(() => auth.currentUser, []);

  useEffect(() => {
    console.log(user);
    const fetchTickets = async () => {
      setIsLoading(true);
      const ticketsRef = collection(db, "maintenanceRequests");
      try {
        const querySnapshot = await getDocs(ticketsRef);
        const tickets = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const ticketData = doc.data();
            const bidsRef = collection(
              db,
              `maintenanceRequests/${doc.id}/bids`
            );
            const bidsSnapshot = await getDocs(bidsRef);
            const bids = bidsSnapshot.docs.map((bidDoc) => ({
              id: bidDoc.id,
              ...bidDoc.data(),
            }));
            return {
              id: doc.id,
              bids,
              ...ticketData,
              requestedDate: formatDate(ticketData.requestedDate),
            };
          })
        );

        setPendingTickets(
          tickets.filter((ticket) => ticket.status === "Pending")
        );
        setResolvedTickets(
          tickets.filter((ticket) => ticket.status === "Resolved")
        );
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const getContractorName = async (uid) => {
    try {
      const userDoc = doc(db, "users", uid);
      const userSnap = await getDoc(userDoc);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        return `${userData.firstName} ${userData.lastName}`; // Combining first and last name
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
      return null;
    }
  };

  const handleBidSubmit = async (ticketId) => {
    setSubmitting(true);
    const bidRef = collection(db, `maintenanceRequests/${ticketId}/bids`);
    const ticketIndex = pendingTickets.findIndex(
      (ticket) => ticket.id === ticketId
    );
    const existingBid = pendingTickets[ticketIndex].bids.find(
      (bid) => bid.contractorId === user.uid
    );

    const contractorName = await getContractorName(user.uid);
    if (!contractorName) {
      toast.error("Contractor name not found.");
      setSubmitting(false);
      return;
    }

    try {
      if (existingBid) {
        const bidDocRef = doc(
          db,
          `maintenanceRequests/${ticketId}/bids`,
          existingBid.id
        );
        await updateDoc(bidDocRef, {
          amount: bidAmount[ticketId],
          contractorName, // Updated to use the full name
          timestamp: serverTimestamp(),
        });
        // Update existing bid in local state
        const updatedBids = pendingTickets[ticketIndex].bids.map((bid) =>
          bid.id === existingBid.id
            ? { ...bid, amount: bidAmount[ticketId], contractorName } // Updating bid amount and contractor name
            : bid
        );
        const updatedTickets = [...pendingTickets];
        updatedTickets[ticketIndex] = {
          ...updatedTickets[ticketIndex],
          bids: updatedBids,
        };
        setPendingTickets(updatedTickets);
      } else {
        const newBidRef = await addDoc(bidRef, {
          contractorId: user.uid,
          contractorName, // Updated to use the full name
          amount: bidAmount[ticketId],
          timestamp: serverTimestamp(),
        });
        // Add new bid to local state
        const newBid = {
          id: newBidRef.id,
          contractorId: user.uid,
          contractorName, // Using the full name
          amount: bidAmount[ticketId],
        };
        const updatedTickets = [...pendingTickets];
        updatedTickets[ticketIndex].bids = [
          ...updatedTickets[ticketIndex].bids,
          newBid,
        ];
        setPendingTickets(updatedTickets);
      }
      toast.success("Bid submitted successfully!");
    } catch (error) {
      console.error("Failed to submit bid:", error);
      toast.error("Failed to submit bid.");
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen">
        <Spinner className="text-black dark:text-white mx-auto animate-spin" />
      </div>
    );
  }

  if (!pendingTickets.length && !resolvedTickets.length) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen text-white">
        <p>No tickets found.</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="flex flex-col w-full min-h-screen overflow-y-auto bg-black"
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex justify-between w-full p-4">
        <div className="w-full mr-4">
          <h2 className="text-xl font-bold text-white">Pending Tickets</h2>
          <div className="flex flex-col gap-3 mt-2">
            {pendingTickets.map((ticket) => (
              <div
                key={ticket.id}
                className="p-4 bg-gray-300 shadow-md rounded-lg"
              >
                <h3 className="text-lg font-bold">{ticket.issueType}</h3>
                <p>Ticket Created On: {ticket.requestedDate}</p>
                <p>Status: {ticket.status}</p>
                <p>Fees: {ticket.fees || "N/A"}</p>
                <p>Description: {ticket.issueDescription}</p>
                {ticket.bids && ticket.bids.length > 0 && (
                  <>
                    <p>Previous Bids:</p>
                    {ticket.bids.map((bid) => (
                      <p key={bid.id}>
                        {bid.contractorName}: ${bid.amount}
                      </p>
                    ))}
                  </>
                )}
                <div>
                  <input
                    type="number"
                    value={bidAmount[ticket.id] || ""}
                    onChange={(e) =>
                      setBidAmount({
                        ...bidAmount,
                        [ticket.id]: e.target.value,
                      })
                    }
                    className="mr-2"
                  />
                  <button onClick={() => handleBidSubmit(ticket.id)}>
                    Submit Bid
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full ml-4">
          <h2 className="text-xl font-bold text-white">Resolved Tickets</h2>
          <div className="flex flex-col gap-3 mt-2">
            {resolvedTickets.map((ticket) => (
              <div
                key={ticket.id}
                className="p-4 bg-gray-200 shadow-md rounded-lg"
              >
                <h3 className="text-lg font-bold">{ticket.issueType}</h3>
                <p>Ticket Created On: {ticket.requestedDate}</p>
                <p>Status: {ticket.status}</p>
                <p>Fees: {ticket.fees || "N/A"}</p>
                <p>Description: {ticket.issueDescription}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
