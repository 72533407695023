import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import App from "./App";
import TenantDashboard from "./pages/dashboard/Tenant/TenantDashboard";
import LandlordDashboard from "./pages/dashboard/Landlord/LandlordDashboard";
import ContractorDashboard from "./pages/dashboard/Contractor/ContractorDashboard";
import { useAuth } from "./AuthContext";
import ListingDetails from "./pages/listing/ListingsDetails";
import PaymentGateway from "./pages/paymentGateway/PaymentGateway";
import Chats from "./pages/chats/Chats";
import BidsPage from "./pages/bids/Bids";

// Protect routes that require authentication
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" replace />;
  }

  return children;
};

const RoutesComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path={"/tenant-dashboard"}
        element={
          <ProtectedRoute>
            <TenantDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/landlord-dashboard"}
        element={
          <ProtectedRoute>
            <LandlordDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={"/contractor-dashboard"}
        element={
          <ProtectedRoute>
            <ContractorDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/listing/:id"
        element={
          <>
            <ListingDetails />
          </>
        }
      />
      <Route path="/ticket/messages/:id" element={<Chats />} />
      <Route path="/ticket/bids/:id" element={<BidsPage />} />
      <Route path="/payment/:id" element={<PaymentGateway />} />
    </Routes>
  </Router>
);

export default RoutesComponent;
