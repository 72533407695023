import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { Spinner } from "../../../../components/Svgs";
export default function Chats() {
  const [chats, setChats] = useState([]);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchChats = async () => {
      if (!currentUser) return;

      const ticketChatsRef = collection(db, "ticketChats");

      // Query for documents where issuedBy or issuedTo matches the current user
      const issuedByQuery = query(
        ticketChatsRef,
        where("issuedBy", "==", doc(db, "users", currentUser.uid))
      );
      const issuedToQuery = query(
        ticketChatsRef,
        where("issuedTo", "==", doc(db, "users", currentUser.uid))
      );

      try {
        // Fetch issuedBy and issuedTo documents
        const [issuedBySnapshot, issuedToSnapshot] = await Promise.all([
          getDocs(issuedByQuery),
          getDocs(issuedToQuery),
        ]);

        // Function to resolve references
        const resolveReferences = async (chats, role) => {
          return Promise.all(
            chats.map(async (chatDoc) => {
              const data = chatDoc.data();
              const issuedBy = await getDoc(data.issuedBy); // Resolve issuedBy reference
              const issuedTo = await getDoc(data.issuedTo); // Resolve issuedTo reference
              const ticket = await getDoc(data.ticketId); // Resolve ticket reference
              return {
                ...data,
                id: chatDoc.id,
                issuedBy: { id: issuedBy.id, ...issuedBy.data() }, // Include resolved data
                issuedTo: { id: issuedTo.id, ...issuedTo.data() },
                ticket: { id: ticket.id, ...ticket.data() },
                role,
              };
            })
          );
        };

        // Resolve references and assign roles
        const issuedByChats = await resolveReferences(
          issuedBySnapshot.docs,
          "tenant"
        );
        const issuedToChats = await resolveReferences(
          issuedToSnapshot.docs,
          "landlord"
        );

        // Combine the two lists
        setChats([...issuedByChats, ...issuedToChats]);
      } catch (error) {
        console.error("Error fetching chats: ", error);
      }
    };

    fetchChats();
  }, [currentUser]);

  return (
    <div className="min-h-screen text-white">
      <h1 className="text-xl my-3">Open ticket Chats</h1>
      {/* Render UI with fetched chats */}
      <ul className="w-full smartphone:justify-center flex flex-wrap gap-4 px-4">
        {chats.length > 0 ? (
          chats
            .filter((chat) => chat.messages.length > 0)
            .map((chat) => (
              <li
                key={chat.id}
                className="w-80 smartphone:w-full bg-black/5 dark:bg-white/20 text-black dark:text-white p-4 rounded-md"
              >
                <div className="text-[110%] flex flex-col gap-2">
                  <span>
                    <h1>Issue</h1>
                    <h3 className="text-[70%]">{chat.ticket.issueType}</h3>
                  </span>
                  <span>
                    <h1>Tenant's Name</h1>
                    <h3 className="text-[70%]">
                      {`${chat.issuedBy.firstName} ${chat.issuedBy.lastName}`}
                    </h3>
                  </span>
                  <span className="w-full place-self-end flex justify-between mt-3 text-xs">
                    <button className="py-1 px-2 dark:bg-white/20 bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg hover:scale-110 active:scale-100">
                      <Link to={`/ticket/messages/${chat.ticket.id}`}>
                        Messages
                      </Link>
                    </button>
                  </span>
                </div>
              </li>
            ))
        ) : (
          <div className="w-full min-h-screen flex items-center justify-center">
            <Spinner className=" animate-spin ease-in-out mx-auto  dark:text-white text-black " />
          </div>
        )}
      </ul>
    </div>
  );
}
