import React, { useEffect, useState, useMemo } from "react";
import { motion } from "framer-motion";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../../firebase/firebaseConfig";
import { Spinner } from "../../../../components/Svgs";

export default function Profile() {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Memoize user object to avoid redundant access
  const user = useMemo(() => auth.currentUser, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.log("No such user document found!");
        }
      } else {
        console.log("User is not logged in");
      }
    };

    fetchUserData();
  }, [user]);

  // Function to handle save
  const handleSave = async () => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        setIsSaving(true);
        await updateDoc(userDocRef, {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
        });
        setIsEditing(false); // Exit edit mode
      } catch (error) {
        console.error("Error updating document: ", error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Input change handler to update `userData`
  const handleChange = (field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.8 }}
      className="w-full   mt-2  dark:bg-white/10 bg-black/15 p-4 rounded-md text-black dark:text-white"
      style={{ height: "670px" }}
    >
      {userData ? (
        <div>
          <div className="flex flex-col text-2xl mb-4">
            <strong>Name</strong>
            <div className="flex flex-wrap smartphone:gap-2  gap-10 text-base my-1">
              {["firstName", "lastName"].map((field) => (
                <div key={field} className="flex flex-col mb-2">
                  <span>
                    {field === "firstName" ? "First Name" : "Last Name"}
                  </span>
                  {isEditing ? (
                    <input
                      type="text"
                      value={userData[field] || ""}
                      onChange={(e) => handleChange(field, e.target.value)}
                      className="w-56 border-b outline-none border-white p-1 bg-white/10 focus:bg-brand-blue/10 focus:border-brand-blue"
                    />
                  ) : (
                    <h1 className="w-56 p-1">{userData[field]}</h1>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col text-2xl mb-4">
            <strong>Phone Number</strong>
            {isEditing ? (
              <input
                type="text"
                value={userData.phone || ""}
                onChange={(e) => handleChange("phone", e.target.value)}
                className="w-56 text-base border-b  outline-none border-white p-1 bg-white/10 focus:bg-brand-blue/10 focus:border-brand-blue"
              />
            ) : (
              <h1 className="w-56 p-1 text-base">{userData.phone}</h1>
            )}
          </div>
          <div className="flex flex-col text-2xl mb-4">
            <strong>Email</strong>
            <h1 className="w-fit p-1 text-base">{userData.email}</h1>
          </div>
          <div className="flex flex-col text-2xl mb-4">
            <strong>Role</strong>
            <h1 className="w-56 p-1 text-base">{userData.role}</h1>
          </div>
          <button
            onClick={() => (isEditing ? handleSave() : setIsEditing(true))}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            {isSaving ? (
              <div className="flex gap-4">
                Saving
                <Spinner className="animate-spin ease-in-out w-5 text-white" />
              </div>
            ) : isEditing ? (
              "Save"
            ) : (
              "Edit"
            )}
          </button>
        </div>
      ) : (
        <div className="w-full min-h-screen  col-span-3 grid place-content-center">
          <Spinner className="animate-spin ease-in-out w-20 text-white" />
        </div>
      )}
    </motion.div>
  );
}
