import { Moon, Sun } from "./Svgs";
import { motion } from "framer-motion";
export default function DarkModeToggle({ darkMode, setDarkMode }) {
  return (
    <div
      onClick={() => setDarkMode(!darkMode)}
      className={` transition-colors `}
    >
      <SliderToggle setSelected={setDarkMode} selected={darkMode} />
    </div>
  );
}
const TOGGLE_CLASSES =
  "text-[70%] font-medium flex justify-center items-center gap-1 px-2  transition-colors duration-100  relative z-10";
const SliderToggle = ({ selected, setSelected }) => {
  return (
    <div className="relative flex gap-0  w-fit items-center rounded-full">
      <button
        className={`${TOGGLE_CLASSES} `}
        onClick={() => {
          setSelected(false);
        }}
      >
        <Moon className="z-10  w-3" />
        <span className="z-10 ">Light</span>
      </button>
      <button
        className={`${TOGGLE_CLASSES}`}
        onClick={() => {
          setSelected(true);
        }}
      >
        <Sun className="z-10  w-4" />
        <span className="z-10">Dark</span>
      </button>
      <div
        className={`absolute inset-0 z-0 flex ${
          selected === true ? "justify-end" : "justify-start"
        }`}
      >
        <motion.span
          layout
          transition={{ duration: 0.1, damping: 15, stiffness: 250 }}
          className="h-full w-[49%] rounded-full bg-gradient-to-br from-red-500 to-firebase-accent"
        />
      </div>
    </div>
  );
};
