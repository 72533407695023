import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  Timestamp,
  collection,
  serverTimestamp,
  arrayUnion,
  DocumentReference,
} from "firebase/firestore";
import { auth, db } from "../../firebase/firebaseConfig";
import formatDate from "../../helpers/formatDate";
import { ArrowUpRight, Plane } from "../../components/Svgs";
import { AnimatePresence, motion } from "framer-motion";
export default function Chats() {
  const { id } = useParams(); // Ticket ID from the route
  const [chatRef, setChatRef] = useState(null); // Firebase reference to the chat document
  const [messages, setMessages] = useState([]); // To store chat messages
  const [newMessage, setNewMessage] = useState(""); // For user input
  const [userRef, setUserRef] = useState(null); // Reference of the current user
  const [sender, setSender] = useState(); //Sender doc
  const [role, setRole] = useState(""); // Role of the current user (tenant or landlord)
  const [ticket, setTicket] = useState(null);
  const [isTicketExpanded, setIsTicketExpanded] = useState(null);
  const chatboxDivRef = useRef(null);
  useEffect(() => {
    const loadChat = async () => {
      try {
        const ticketRef = doc(db, "maintenanceRequests", id);
        const ticketDoc = await getDoc(ticketRef);
        const user = auth.currentUser;

        if (!ticketDoc.exists()) {
          console.error(`Ticket with ID ${id} does not exist.`);
          return;
        } else {
          setTicket({ id, ...ticketDoc.data() });
        }

        const { issuedTo, issuedBy, chatId } = ticketDoc.data();
        if (!issuedTo || !issuedBy) {
          console.error(
            "Invalid ticket: Missing issuedTo, issuedBy, or chatId."
          );
          return;
        }

        const currentUserRef = user.uid;
        const setSenderDoc = async (reference) => {
          const senderDocument = await getDoc(reference);
          if (senderDocument.exists()) {
            setSender(senderDocument.data());
          } else {
            console.error("Sender document does not exist.");
          }
        };

        if (currentUserRef === issuedBy.id) {
          setUserRef(issuedBy);
          setRole("tenant");
          setSenderDoc(issuedTo);
        } else if (currentUserRef === issuedTo.id) {
          setUserRef(issuedTo);
          setRole("landlord");
          setSenderDoc(issuedBy);
        } else {
          console.error("Current user is neither tenant nor landlord.");
          return;
        }

        let chatDocRef;

        // Step 2: Check if the chat document exists or create one
        if (chatId && chatId instanceof DocumentReference) {
          chatDocRef = chatId; // Use the existing Firebase reference
          setChatRef(chatDocRef); // Store the chat reference
        } else {
          // Create a new chat document only if chatId is not valid
          chatDocRef = doc(collection(db, "ticketChats"));
          const newChatData = {
            issuedTo,
            issuedBy,
            ticketId: ticketRef,
            messages: [],
            createdAt: serverTimestamp(),
          };
          await setDoc(chatDocRef, newChatData);
          // Update the ticket with the newly created chatId
          await updateDoc(ticketRef, { chatId: chatDocRef });
          setChatRef(chatDocRef);
        }

        // Set up real-time listener
        const unsubscribe = onSnapshot(chatDocRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.data();
            setMessages(data.messages || []);
            if (chatboxDivRef.current) {
              chatboxDivRef.current.scrollTo({
                top: chatboxDivRef.current.scrollHeight,
                behavior: "smooth",
              });
            }
          }
        });

        return () => unsubscribe(); // Clean up listener when component unmounts
      } catch (error) {
        console.error("Error loading chat:", error);
      }
    };

    loadChat();
  }, [id]);
  useEffect(() => {
    if (chatboxDivRef.current) {
      chatboxDivRef.current.scrollTo({
        top: chatboxDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);
  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    if (!chatRef) {
      console.error("Chat reference is not set.");
      return;
    }

    try {
      const chatDoc = await getDoc(chatRef);

      if (chatDoc.exists()) {
        // Use arrayUnion for more efficient updates
        await updateDoc(chatRef, {
          messages: arrayUnion({
            userId: userRef, // Firebase reference of the sender
            message: newMessage,
            role: role, // Dynamic role
            time: Timestamp.now(),
          }),
        });

        setNewMessage(""); // Clear the input field
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center  bg-black/80">
      <AnimatePresence mode="wait">
        {isTicketExpanded && (
          <TicketDetails
            ticket={ticket}
            setIsTicketExpanded={setIsTicketExpanded}
          />
        )}
      </AnimatePresence>
      <header className="h-[10%] w-full md:w-[90%] lg:w-2/3 flex justify-between items-center  bg-black/10 text-white p-4 rounded-t-md">
        <h1 className="text-lg font-semibold">
          {role === "tenant" ? "Landlord" : "Tenant"} -{" "}
          {`${sender?.firstName} ${sender?.lastName}` || "ticket issue"}
        </h1>
        <button onClick={() => setIsTicketExpanded(!isTicketExpanded)}>
          See Ticket info.
        </button>
      </header>

      <ChatComponent
        chatboxDivRef={chatboxDivRef}
        sendMessage={sendMessage}
        role={role}
        id={id}
        messages={messages}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        userRef={userRef}
      />
    </div>
  );
}
const ChatComponent = ({
  id,
  role,
  chatboxDivRef,
  messages,
  newMessage,
  setNewMessage,
  sendMessage,
  userRef,
}) => {
  return (
    <div className="w-full h-[90%] flex flex-col items-center ">
      {/* Chat Messages Section */}
      <div
        ref={chatboxDivRef}
        className="w-full md:w-[90%] lg:w-2/3 h-full flex flex-col overflow-y-scroll p-4 bg-white shadow-inner"
      >
        {messages.length === 0 ? (
          <div className="w-full  text-gray-400 text-center my-auto">
            No messages yet
          </div>
        ) : (
          messages.map((msg, index) => (
            <div
              key={index}
              className={`relative max-w-[75%]  flex flex-col my-2 px-1.5  py-2.5 rounded-b-lg shadow ${
                msg.userId.id !== userRef.id
                  ? "self-start bg-gray-200 text-black rounded-r-lg"
                  : "self-end bg-firebase-accent text-white rounded-l-lg"
              }`}
            >
              <span
                className={`min-w-12 text-sm w-full text-pretty ${
                  msg.userId.id !== userRef.id ? "text-start" : "text-end"
                }`}
              >
                {msg.message}
              </span>
              <span
                className={` text-[65%]  mt-1.5 -mb-2 ${
                  msg.userId.id !== userRef.id
                    ? "text-gray-600"
                    : "text-gray-100 text-end"
                }`}
              >
                {formatDate(msg.time).time}
              </span>
              <div
                style={{
                  clipPath:
                    msg.userId.id !== userRef.id
                      ? "polygon(0% 0%, 100% 0%, 50% 100%)" // Left-pointing triangle
                      : "polygon(0% 0%, 100% 0%, 50% 100%)", // Right-pointing triangle
                  // Flip for left user
                  width: "17px", // Adjust size
                  height: "6px",
                }}
                className={`absolute ${
                  msg.userId.id !== userRef.id
                    ? "-left-2 top-0 bg-gray-200" // Position for left user
                    : "-right-2 top-0 bg-firebase-accent" // Position for right user
                }`}
              />
            </div>
          ))
        )}
      </div>

      {/* Message Input Section */}
      <div className="w-full md:w-[90%] lg:w-2/3 flex items-center p-3 bg-gray-100 rounded-b-md shadow">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type Your Message"
          className="flex-1 px-4 py-3 bg-white text-black border border-gray-300 rounded-full outline-none placeholder-gray-500 focus:ring-2 focus:ring-firebase-accent caret-firebase-accent"
        />
        <button
          onClick={sendMessage}
          disabled={!newMessage.trim()}
          className="ml-2 p-3 text-white bg-firebase-accent hover:bg-firebase-accent/80 active:scale-95 rounded-full transition-all duration-150 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          <Plane className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};
const TicketDetails = ({ ticket, setIsTicketExpanded }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="w-screen h-screen fixed top-0 left-0 z-50 py-4 backdrop-blur bg-black/70 flex justify-center items-start"
    >
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-5xl  w-full h-full  overflow-y-auto">
        {/* Header */}
        <div className="w-full flex items-center justify-start mb-6">
          <button
            onClick={() => setIsTicketExpanded(false)}
            className="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 group"
          >
            <ArrowUpRight className="w-6 rotate-[-135deg] group-hover:-translate-x-[1px] active:translate-x-0 duration-100 transition-all" />
          </button>
          <h2 className="text-2xl font-bold text-gray-800">
            Maintenance Request
          </h2>
        </div>

        {/* Row 1: Two-column layout for Key Details */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <h3 className="text-sm font-medium text-gray-500">Ticket ID</h3>
            <p className="text-lg font-semibold text-gray-800 overflow-x-auto">
              {ticket.id || "N/A"}
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg shadow-md">
            <h3 className="text-sm font-medium text-gray-500">Status</h3>
            <p
              className={`text-lg font-semibold ${
                ticket.status === "Completed"
                  ? "text-green-600"
                  : "text-orange-500"
              }`}
            >
              {ticket.status || "Pending"}
            </p>
          </div>
        </div>

        {/* Row 2: Single-column layout for additional info */}
        <div className="space-y-3">
          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">Issue</h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.issueType || "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">
              Requested Date
            </h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.requestedDate
                ? formatDate(ticket.requestedDate).inString
                : "Pending"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">Fees</h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.fees ? `$${ticket.fees}` : "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-sm font-medium text-gray-500">
              Completion Date
            </h3>
            <p className="text-lg font-semibold text-gray-800">
              {ticket.completionDate
                ? ticket.completionDate.toDate().toLocaleDateString()
                : "N/A"}
            </p>
          </div>
        </div>

        {/* Row 3: Full-width single-column layout for description */}
        <div className="p-4 bg-gray-50 rounded-lg shadow-md mt-6">
          <h3 className="text-sm font-medium text-gray-500">
            Issue Description
          </h3>
          <p className="text-gray-800">{ticket.issueDescription || "N/A"}</p>
        </div>
      </div>
    </motion.div>
  );
};
