import React from "react";
import {
  Buildings,
  Chat,
  Flag,
  Money,
  Profile,
  Ticket,
  Wrench,
} from "../../../components/Svgs";

// Mapping names to icons for dynamic rendering
const iconMap = {
  Profile: <Profile className="w-5 h-5" />,
  Payments: <Money className="w-5 h-5" />,
  Tickets: <Ticket className="w-5 h-5" />,
  Chats: <Chat className="w-5 h-5" />,
  "My Property": <Buildings className="w-5 h-5" />,
  Properties: <Buildings className="w-5 h-5" />,
  Money: <Money className="w-5 h-5" />,
  Chat: <Chat className="w-5 h-5" />,
  Bids: <Flag className="w-5 h-5" />,
};

export default function Navbar({ mainbar, setMainbar, Items }) {
  const sidebarItems = Items.map((item) => ({
    name: item,
    icon: iconMap[item], // Default icon if not found in map
    onClick: () => setMainbar(item),
  }));

  return (
    <div className="w-screen h-14 z-[500] pt-2 fixed bottom-0 flex justify-center bg-white/95 dark:bg-firebase-bg dark:text-firebase-text text-black/60 px-4 border-r border:black/20 dark:border-white/20 transition-all duration-300">
      <ul className="flex flex-row gap-2">
        {sidebarItems.map((sidebarItem) => (
          <li
            key={sidebarItem.name}
            onClick={sidebarItem.onClick}
            className={`py-1 px-2 cursor-pointer flex flex-col items-center transition-all duration-300 text-[80%] rounded-t-2xl active:scale-95 hover:scale-105 ${
              mainbar === sidebarItem.name
                ? "bg-firebase-accent text-black"
                : "dark:hover:bg-gray-300/10 hover:bg-gray-800/30"
            } select-none`}
          >
            {sidebarItem.icon}
            <span className="tracking-wide">{sidebarItem.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
