import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../../firebase/firebaseConfig";
import { Spinner } from "../../../../components/Svgs";

export default function Profile() {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(""); // Added state for email
  const [role, setRole] = useState(""); // Added state for role

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setPhone(data.phone);
          setEmail(data.email); // Initialize with fetched email
          setRole(data.role); // Initialize with fetched role
        } else {
          console.log("No such user document found!");
        }
      } else {
        console.log("User is not logged in");
      }
    };

    fetchUserData();
  }, []);

  // Function to handle save
  const handleSave = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        await updateDoc(userDocRef, {
          firstName,
          lastName,
          phone,
          // Note: email and role are not updated since they are not editable
        });
        setIsEditing(false); // Exit edit mode
        setUserData((prevData) => ({
          ...prevData,
          firstName,
          lastName,
          phone,
          // Keep email and role unchanged
        }));
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  return (
    <div className="w-full dark:bg-white/10 bg-black/15 p-4 rounded-md text-black dark:text-white">
      {userData ? (
        <div>
          {/* Name Section */}
          <div className="flex flex-col text-2xl mb-4">
            <strong>Name</strong>
            <div className="flex flex-wrap smartphone:gap-2  gap-10 text-base my-1">
              <div className="flex flex-col mb-2">
                <span>First Name</span>
                {isEditing ? (
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="w-56 border-b outline-none border-white p-1 bg-white/10 focus:bg-firebase-accent/10 focus:border-firebase-accent"
                  />
                ) : (
                  <h1 className="w-56 p-1">{firstName}</h1>
                )}
              </div>
              <div className="flex flex-col mb-2">
                <span>Last Name</span>
                {isEditing ? (
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="w-56 border-b outline-none border-white p-1 bg-white/10 focus:bg-firebase-accent/10 focus:border-firebase-accent"
                  />
                ) : (
                  <h1 className="w-56 p-1">{lastName}</h1>
                )}
              </div>
            </div>
          </div>

          {/* Phone Number Section */}
          <div className="flex flex-col text-2xl mb-4">
            <strong>Phone Number</strong>
            {isEditing ? (
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-56 text-base border-b outline-none border-white p-1 bg-white/10 focus:bg-firebase-accent/10 focus:border-firebase-accent"
              />
            ) : (
              <h1 className="w-56 p-1 text-base">{phone}</h1>
            )}
          </div>

          {/* Email and Role (Read-Only) */}
          <div className="flex flex-col text-2xl mb-4">
            <strong>Email</strong>
            <h1 className="w-fit p-1 text-base">{email}</h1>
          </div>
          <div className="flex flex-col text-2xl mb-4">
            <strong>Role</strong>
            <h1 className="w-56 p-1 text-base">{role}</h1>
          </div>

          {/* Edit/Save Button */}
          <button
            onClick={() => (isEditing ? handleSave() : setIsEditing(true))}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            {isEditing ? "Save" : "Edit"}
          </button>
        </div>
      ) : (
        <div className="w-full col-span-3 h-[260px] grid place-content-center">
          <Spinner className="animate-spin ease-in-out w-20 text-white" />
        </div>
      )}
    </div>
  );
}
