import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Profile from "./components/Profile";
import Communications from "./components/Communication";
import DarkModeToggle from "../../../components/DarkModeToggle";
import { AnimatePresence } from "framer-motion";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase/firebaseConfig";

// Use an object mapping for better readability and scalability
const DynamicSection = (section) => {
  const sections = {
    Profile: Profile,
    Tickets: Communications,
  };

  // Return the selected component, defaulting to Profile if none matches
  const SelectedSection = sections[section] || Profile;
  return <SelectedSection />;
};

function ContractorDashboard() {
  const [mainbar, setMainbar] = useState("Profile");
  const [darkMode, setDarkMode] = useState(true);
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User successfully logged out");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className={`min-h-screen w-screen bg-gradient-to-r flex flex-row items-start justify-start ${
        darkMode && "dark"
      }`}
    >
      <Sidebar
        mainbar={mainbar}
        setMainbar={setMainbar}
        Items={["Profile", "Tickets"]}
      />
      <div className="w-full  flex  flex-col justify-start items-start  dark:bg-firebase-bg bg-white/95  px-5 py-1 duration-300 transition-all">
        <div className="w-full h-10 dark:text-white text-black  py-8  text-[100%] flex justify-between items-center border-b dark:border-white/20 border-black/20 duration-100 transition-all">
          <span className="text-2xl smartphone:text-base">
            Contractor-Dashboard
          </span>
          <span className="flex gap-1 items-center">
            <DarkModeToggle darkMode={darkMode} setDarkMode={setDarkMode} />
            <button
              onClick={handleLogout}
              className="ml-3 bg-red-500 hover:bg-red-600 hover:scale-105 active:scale-100  text-white text-xs  font-medium py-2 px-4 rounded-3xl transition duration-300 ease-in-out"
            >
              <span className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Logout
              </span>
            </button>
          </span>
        </div>
        <div className="w-full pb-16">
          <AnimatePresence mode="wait">
            {DynamicSection(mainbar)}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default ContractorDashboard;
