import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase/firebaseConfig"; // Include db for Firestore
import { doc, getDoc } from "firebase/firestore";
import Logo from "../assets/Logo.png";

export default function Header() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null); // Additional Firestore data

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);
        console.log("Auth User:", authUser);

        // Fetch additional Firestore user data if needed
        const userDocRef = doc(db, "users", authUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
          console.log("Firestore User Data:", userDoc.data());
        } else {
          console.log("No Firestore data found for user.");
        }
      } else {
        setUser(null);
        setUserData(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      setUserData(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.2, ease: "easeInOut" }}
      className="w-screen h-16 fixed top-0 z-10 px-4 pl-10 flex flex-row justify-between items-center bg-black/90 overflow-x-hidden"
    >
      {/* Logo Section */}
      <div className="w-[20%]">
        <img
          src={Logo}
          alt="Realty Plus Logo"
          className="w-20 h-full object-cover object-start "
        />
      </div>

      {/* User Greeting / Auth Links */}
      <div className="smartphone:w-[80%] w-1/3  flex justify-end gap-3">
        {user ? (
          <div className="flex items-center text-white gap-2">
            {/* Greeting */}
            <span className="text-lg smartphone:text-base  font-semibold">
              Hi,{" "}
              {userData?.firstName + " " + userData?.lastName ||
                user.displayName ||
                "User"}
              !
            </span>

            {/* Sign Out Button */}
            <button
              onClick={handleSignOut}
              className="ml-3 bg-red-500 hover:bg-red-600 hover:scale-105 active:scale-100  text-white text-xs  font-medium py-2 px-4 rounded-3xl transition duration-300 ease-in-out"
            >
              <span className="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Logout
              </span>
            </button>
          </div>
        ) : (
          <>
            <Link
              to="/login"
              className="text-lg text-white font-semibold px-4 py-2 hover:text-firebase-accent transition duration-150 ease-in-out"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="text-lg text-firebase-accent font-semibold bg-white rounded-full px-4 py-2 hover:bg-firebase-accent/10 transition duration-150 ease-in-out"
            >
              Register
            </Link>
          </>
        )}
      </div>
    </motion.div>
  );
}
