import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { ArrowUpRight, Pause, Play } from "../../components/Svgs";
import Header from "../../components/Header";

export default function ListingDetails() {
  const { id } = useParams(); // Get the document ID from the URL
  const [listing, setListing] = useState(null);

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, "listings", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setListing(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchListing();
  }, [id]);

  return (
    <div className="w-full h-full bg-firebase-bg text-white pt-20">
      <Header />
      {listing ? (
        <div className="">
          <h2 className="text-2xl font-bold mb-4">{listing.name}</h2>
          <ImageGallery images={listing.images} />
          <p>
            <strong>Price:</strong> ${listing.Money.price}
          </p>
          <p>
            <strong>Description:</strong> {listing.longDescription}
          </p>
          {/* Add more fields as necessary */}
        </div>
      ) : (
        <p>Loading listing details...</p>
      )}
    </div>
  );
}

const ImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true); // Slideshow control state

  // Handles the automatic image change for the slideshow
  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(() => {
        handleNext();
      }, 6000); // Change image every 6 seconds

      return () => clearInterval(timer); // Cleanup timer on component unmount
    }
  }, [currentImageIndex, isPlaying]);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const toggleSlideshow = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="flex gap-3 h-96 p-4">
      <div className="w-3/4 relative border bg-white/5 border-black/10 rounded-xl">
        <AnimatePresence mode="wait">
          <motion.img
            key={currentImageIndex}
            src={images[currentImageIndex]}
            alt={`Image ${currentImageIndex + 1}`}
            className="w-full h-full object-contain rounded-md transition-all"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          />
        </AnimatePresence>
        <button
          onClick={handlePrevious}
          className="px-4 py-2 absolute top-1/2 left-[-1px]  backdrop-blur-md bg-black/50 hover:bg-black/80 text-white rounded-r-lg"
        >
          <ArrowUpRight className="-rotate-[135deg] hover:-translate-x-1 hover:scale-105 active:translate-x-0 transition-all duration-300" />
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 absolute top-1/2 right-[-1px]  backdrop-blur-md bg-black/50 hover:bg-black/80 text-white rounded-l-lg"
        >
          <ArrowUpRight className="rotate-45 hover:translate-x-1 hover:scale-105 active:translate-x-0 transition-all duration-300" />
        </button>
        {/* Slideshow toggle button */}
        <button
          onClick={toggleSlideshow}
          className="absolute bottom-2 right-2 px-3 py-2 text-sm flex gap-2 items-center justify-center bg-black/70 backdrop-blur-lg  text-white rounded-md hover:bg-black/80 transition"
        >
          {!isPlaying ? <Play /> : <Pause />} Slideshow
        </button>
      </div>
      <div className="thumbnails bg-white/5 w-1/4 p-3  flex justify-center flex-wrap gap-1 overflow-y-scroll rounded-xl">
        {images.map((image, index) => (
          <div
            key={index}
            className="group overflow-hidden rounded-lg hover:rounded-md"
          >
            <div
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
              }}
              className={`size-24 rounded-lg object-cover cursor-pointer group-hover:scale-105 ${
                index === currentImageIndex ? "border-2 border-white" : ""
              } transition-all`}
              onClick={() => {
                setCurrentImageIndex(index);
                setIsPlaying(false); // Pause slideshow on manual selection
              }}
            >
              <div
                className={`w-full h-full hover:bg-black/30 ${
                  index === currentImageIndex ? "bg-black/30" : ""
                }`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
