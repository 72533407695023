import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    setError("");

    // Check if all fields are filled
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !password ||
      !confirmPassword ||
      !role
    ) {
      setError("All fields are required. Please fill out each field.");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Construct the path dynamically based on the role selected
      // const path = `${role}s/${user.uid}`; // roles stored as 'tenants', 'landlords', 'contractors'

      // Set additional user details in Firestore under their respective role
      await setDoc(doc(db, "users", user.uid), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        role: role,
      });
      const capitalizeRole = role.charAt(0).toUpperCase() + role.slice(1);

      toast.success(`${capitalizeRole} registered successfully!`);

      setTimeout(() => {
        navigate("/login");
      }, 3000); // 3000ms = 3 seconds delay
      console.log("User registered:", userCredential);
    } catch (error) {
      toast.error("Registration unsuccessful!");
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="max-w-md w-full mx-auto">
        <div className="text-3xl font-bold text-gray-900 mt-2 text-center">
          Create Account
        </div>
        <div className="text-center text-gray-600 mb-6">
          Please enter your details to register.
        </div>
        <div className="bg-white p-8 border border-gray-300">
          <form onSubmit={handleRegister} className="space-y-6">
            <div className="flex justify-between space-x-3">
              <div className="w-1/2">
                <label
                  htmlFor="firstName"
                  className="text-sm font-bold text-gray-700 block"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="lastName"
                  className="text-sm font-bold text-gray-700 block"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="text-sm font-bold text-gray-700 block"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="text-sm font-bold text-gray-700 block"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="text-sm font-bold text-gray-700 block"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="text-sm font-bold text-gray-700 block"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <fieldset>
              <legend className="text-sm font-bold text-gray-700">Role</legend>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="tenant"
                    checked={role === "tenant"}
                    onChange={() => setRole("tenant")}
                    className="form-radio"
                  />
                  <span className="ml-2">Tenant</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    name="role"
                    value="landlord"
                    checked={role === "landlord"}
                    onChange={() => setRole("landlord")}
                    className="form-radio"
                  />
                  <span className="ml-2">Landlord</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    name="role"
                    value="contractor"
                    checked={role === "contractor"}
                    onChange={() => setRole("contractor")}
                    className="form-radio"
                  />
                  <span className="ml-2">Contractor</span>
                </label>
              </div>
            </fieldset>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-orange-500 hover:bg-orange-600 text-white font-bold rounded focus:outline-none focus:shadow-outline"
              >
                Register
              </button>
            </div>
          </form>
          <div className="text-center mt-6">
            <a
              href="/login"
              className="font-medium text-sm text-gray-600 hover:text-gray-900"
            >
              Already have an account? Sign in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
