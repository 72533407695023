const formatDate = (timestamp) => {
  if (timestamp && typeof timestamp.toDate === "function") {
    const date = timestamp.toDate();
    return {
      date: date.getDate(),
      month: date.toLocaleString("en-US", { month: "long" }),
      year: date.getFullYear(),
      inString: date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      fromFirebase: date.toISOString().split("T")[0],
      toMsec: date.getTime(), // Get milliseconds from the Date object
      time: date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }), // Time in "12:56 PM" format
    };
  } else {
    return {
      date: null,
      month: null,
      year: null,
      inString: "N/A", // Default value if the timestamp is invalid
      fromFirebase: "N/A", // Default value for invalid timestamps
      toMsec: null, // Default for invalid timestamps
      time: "N/A", // Default value for invalid timestamps
    };
  }
};

export default formatDate;
