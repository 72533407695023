import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { db } from "../../../../firebase/firebaseConfig"; // Adjust path to your config
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { ArrowUpRight, Spinner, Trash } from "../../../../components/Svgs";
import { getAuth } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";

export default function Listings() {
  const [listings, setListings] = useState([]);
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [landlordId, setLandlordId] = useState(null);
  const [propertyEditId, setPropertyEditId] = useState(null);
  useEffect(() => {
    const fetchListingsForLoggedInLandlord = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      const landlordId = user.uid; // Logged-in user's UID
      setLandlordId(landlordId);
      // Firestore query to fetch listings where landlord.id matches the logged-in user's ID
      const listingsCollection = collection(db, "listings");
      const q = query(
        listingsCollection,
        where("landlord.id", "==", landlordId)
      );

      const listingSnapshot = await getDocs(q);
      const listingList = listingSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setListings(listingList);
    };

    fetchListingsForLoggedInLandlord();
  }, [isFormExpanded]);

  const handlePropertyClick = (id) => {
    setPropertyEditId(id);
    setIsFormExpanded(true);
  };

  const handleDelete = async (id) => {
    try {
      // Reference to the document you want to delete
      const docRef = doc(db, "listings", id);

      // Delete the document
      await deleteDoc(docRef);

      toast.success("Listing Deleted Successfully");
    } catch (error) {
      toast.error("Error deleting Listing ");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.8 }}
      className="w-full min-h-screen   mt-2  flex flex-col   text-black duration-100 transition-all  dark:text-white  rounded-lg p-4 overflow-hidden"
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <button
        onClick={() => setIsFormExpanded(!isFormExpanded)}
        className="place-self-end smartphone:text-sm text-md  py-2 px-5 text-white  hover:bg-firebase-accent/70 bg-firebase-accent hover:scale-[102%] active:scale-100  rounded-lg duration-100 transition-all"
      >
        Add Property
      </button>
      <AnimatePresence>
        {isFormExpanded && (
          <AddProperty
            setIsFormExpanded={setIsFormExpanded}
            landlordId={landlordId}
            propertyEditId={propertyEditId}
            setPropertyEditId={setPropertyEditId}
          />
        )}
      </AnimatePresence>
      <ul
        id="myProperties"
        className="flex flex-col  gap-2 mt-6 smartphone:text-xs text-xl"
      >
        <li className="my-2 mb-4 pb-1  border-b border-offset-4 smartphone:text-xs text-xl  dark:border-white/30 border-black/20   duration-100 transition-all">
          <span>S. no</span>
          <span>Name</span>
          <span>Rent</span>
          <span>Email</span>
          <span>Phone</span>
          <div className="w-12 h-full">View</div>
        </li>
        {listings.length > 0 ? (
          listings.map((listing, index) => (
            <li
              key={listing.id}
              className="relative flex items-center  rounded-2xl   dark:bg-white/10 bg-black/10  overflow-hidden group py-2 px-1 border duration-100 transition-all  dark:border-white/20 border-black/20   hover:scale-[100.5%]"
            >
              <span>{index + 1}</span>
              <span>{listing.name}</span>
              <span>${listing.rent}</span>
              <span>{listing.tenant.email}</span>
              <span>{listing.tenant.phone || "N/A"}</span>
              <div
                className="w-7 smartphone:w-4  cursor-pointer "
                onClick={() => handlePropertyClick(listing.id)}
              >
                <ArrowUpRight className="w-4 group-hover:text-firebase-accent  group-hover:translate-x-[1px] group-hover:-translate-y-[1px] duration-100 transition-all" />
              </div>
              <div className="absolute right-0 top-0 h-full w-8 flex justify-center items-center bg-gradient-to-r from-red-500/5 to-red-500  translate-x-8 group-hover:translate-x-0 transition-all ease-in-out duration-300">
                <button onClick={() => handleDelete(listing.id)}>
                  <Trash className="w-5 dark:text-white text-black" />
                </button>
              </div>
            </li>
          ))
        ) : (
          <li className="gap-4  rounded-2xl bg-white/10 duration-100 transition overflow-hidden group py-2 px-1 border border-white/20  text-white">
            <div className="w-fit ">No Properties posted</div>
            <div className="overflow-hidden w-6">
              <Spinner className=" animate-spin ease-in-out mx-auto  text-white " />
            </div>
          </li>
        )}
      </ul>
    </motion.div>
  );
}

const AddProperty = ({
  setIsFormExpanded,
  landlordId,
  propertyEditId,
  setPropertyEditId,
}) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [rent, setRent] = useState("");
  const [tenantEmail, setTenantEmail] = useState("");
  const [tenantId, setTenantId] = useState(null);
  const [tenantName, setTenantName] = useState("");
  const [tenantPhone, setTenantPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchingTenant, setSearchingTenant] = useState("false");

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (propertyEditId) {
        const propertyRef = doc(db, "listings", propertyEditId);
        const propertyDoc = await getDoc(propertyRef);
        if (propertyDoc.exists()) {
          const propertyData = propertyDoc.data();
          setName(propertyData.name);
          setAddress(propertyData.address);
          if (propertyData?.dueDate) {
            // Convert Firebase Timestamp to YYYY-MM-DD format
            const date = propertyData.dueDate.toDate(); // Converts to JavaScript Date
            const formattedDate = date.toISOString().split("T")[0]; // Extracts YYYY-MM-DD
            setDueDate(formattedDate);
          }
          setRent(propertyData.rent);
          setTenantEmail(propertyData.tenant.email);
          setTenantId(propertyData.tenant.id);
          setTenantPhone(propertyData.tenant.phone);
        } else {
          toast.error("Something Went Wrong");
        }
      }
    };
    fetchPropertyDetails();
  }, [propertyEditId]);

  const fetchTenantId = async (email) => {
    setSearchingTenant("true");
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const userDoc = querySnapshot.docs[0];
    if (userDoc) {
      const userData = userDoc.data();
      setTenantId(userDoc.id);
      setTenantName(`${userData.firstName} ${userData.lastName}`);
      setTenantPhone(userData.phone);
      setSearchingTenant("found");
    } else {
      setSearchingTenant("Tenant not found");
      toast.warning("Ask Your Tenant to SignUp");
      setTenantId(null);
    }
  };

  const handleAddProperty = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Convert dueDate to Firebase Timestamp
      const dueDateTimestamp = Timestamp.fromDate(new Date(dueDate));

      const newListing = {
        name,
        address,
        dueDate: dueDateTimestamp, // Store as Timestamp
        rent: parseFloat(rent),
        tenant: {
          name: tenantName,
          email: tenantEmail,
          id: tenantId,
          phone: tenantPhone,
        },
        landlord: { id: landlordId },
      };

      if (propertyEditId) {
        await setDoc(doc(db, "listings", propertyEditId), newListing);
        toast.success("Property Updated successfully!");
      } else {
        await addDoc(collection(db, "listings"), newListing);
        toast.success("Property Added successfully!");
      }
      setIsFormExpanded(false);
    } catch (error) {
      toast.error("Something Went Wrong");
    } finally {
      setLoading(false);
      setPropertyEditId(null);
      setIsFormExpanded(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      duration-100
      transition={{ delay: 0.2 }}
      className="w-screen h-screen z-[600] fixed top-0 left-0  duration-100 transition-all  dark:bg-black/20 bg-black/25  text-black  dark:text-white backdrop-blur flex justify-center items-start py-10 smartphone:py-0"
    >
      <div className="bg-white/90  shadow-lg shadow-white/15  h-full w-[40%] smartphone:w-full  p-6 rounded-lg text-black  overflow-y-scroll">
        <div className="w-full h-[10%] mb-4 flex gap-4  justify-start items-center">
          <button
            onClick={() => {
              setIsFormExpanded(false);
              setPropertyEditId(null);
            }}
            className="h-full text-black/60 hover:text-black    rounded-xl duration-100 transition-all group"
          >
            <ArrowUpRight className="w-6 rotate-[-135deg] group-hover:-translate-x-[1px] active:translate-x-0 duration-100 transition-all" />
          </button>
          <h2 className="text-2xl font-bold">
            {propertyEditId ? "Edit Property" : "Add Property"}
          </h2>
        </div>
        <form
          onSubmit={handleAddProperty}
          className="h-[85%] flex flex-col  relative"
        >
          <div className="mb-4">
            <label className="block mb-1">Property Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="min-h-24 ">
            <label className="block mb-1">Tenant Email</label>
            <input
              type="email"
              value={tenantEmail}
              onChange={(e) => setTenantEmail(e.target.value)}
              className={`w-full px-3 py-2 border rounded-lg`}
              onBlur={() => fetchTenantId(tenantEmail)}
              required
            />
            {searchingTenant === "true" && (
              <span className="flex items-center text-xs">
                Searching Tenant...
              </span>
            )}
            {searchingTenant === "found" && (
              <div className="text-xs mt-2">
                Tenant Name: {tenantName || "N/A"} <br />
                Tenant Phone: {tenantPhone || "N/A"}
              </div>
            )}
            {searchingTenant === "Tenant not found" && (
              <span className="text-red-500 text-xs">
                Tenant not found, You can add them later
              </span>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-1">Address</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="w-full flex items-center">
            <div className="w-1/2 mb-4 pr-1">
              <label className="block mb-1">Due Date</label>
              <input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
            <div className="w-1/2 mb-4 pl-1">
              <label className="block mb-1">Rent ($)</label>
              <input
                type="number"
                value={rent}
                onChange={(e) => setRent(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg"
                required
              />
            </div>
          </div>

          <button
            onClick={handleAddProperty}
            className="min-w-40 h-10 px-4 py-2 bg-firebase-accent/90 hover:bg-firebase-accent hover:scale-[101%] active:scale-100 text-white rounded-xl duration-100 transition-all"
            disabled={loading}
          >
            {loading ? (
              <div className="w-full flex flex-row-reverse  gap-2 justify-center  items-center">
                <Spinner className="w-5 animate-spin ease-in-out duration-200" />
                {propertyEditId ? "Updating Property" : "Adding Property"}
              </div>
            ) : propertyEditId ? (
              "Update Property"
            ) : (
              "Add Property"
            )}
          </button>
        </form>
      </div>
    </motion.div>
  );
};

/*
 const duplicateListing = async (listing) => {
    try {
      const listingsCollection = collection(db, "listings");

      // Create a copy of the listing data, excluding the original ID
      const { id, ...listingDataWithoutId } = listing;

      // Add the duplicated listing data to Firestore
      const docRef = await addDoc(listingsCollection, listingDataWithoutId);
      console.log("Duplicated listing added with ID: ", docRef.id);
    } catch (error) {
      console.error("Error duplicating listing: ", error);
    }
  };
*/
