import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../AuthContext";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");
    try {
      // Authenticate the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      login(); // Update login state to true

      const user = userCredential.user;

      // Fetch user role from Firestore
      const userDocRef = doc(db, "users", user.uid); // Assuming users collection
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRole = userData.role;
        toast.success("Logged in successfully!");

        // Route based on user role
        if (userRole === "tenant") {
          navigate("/tenant-dashboard");
        } else if (userRole === "landlord") {
          navigate("/landlord-dashboard");
        } else if (userRole === "contractor") {
          navigate("/contractor-dashboard");
        } else {
          toast.error("Role not recognized. Please contact support.");
        }
      } else {
        toast.error("No user data found. Please try again.");
      }
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="max-w-md w-full mx-auto">
        <div className="text-3xl font-bold text-gray-900 mt-2 text-center">
          Welcome back
        </div>
        <div className="text-center text-gray-600 mb-6">
          Welcome back! Please enter your details.
        </div>
        <div className="bg-white p-8 border border-gray-300">
          <form onSubmit={handleLogin} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="text-sm font-bold text-gray-700 block"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="text-sm font-bold text-gray-700 block"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-orange-500 hover:bg-orange-600 text-white font-bold rounded focus:outline-none focus:shadow-outline"
              >
                Sign in
              </button>
            </div>
            <div className="text-center">
              <a
                href="/forgot-password"
                className="font-medium text-sm text-orange-500 hover:text-orange-600"
              >
                Forgot Password
              </a>
            </div>
          </form>
          <div className="text-center mt-6">
            <a
              href="/register"
              className="font-medium text-sm text-gray-600 hover:text-gray-900"
            >
              Don't have an account? Sign up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
