import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import formatDate from "../../../../helpers/formatDate";
import { auth, db } from "../../../../firebase/firebaseConfig";
import {
  ArrowUpRight,
  DropDown,
  Spinner,
  Status,
} from "../../../../components/Svgs";
import { Link } from "react-router-dom";

export default function Rents() {
  const [billInsight, setBillInsight] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [selectedBill, setSelectedBill] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        // Get the logged-in user's ID
        const loggedInUserId = auth.currentUser?.uid;

        if (!loggedInUserId) {
          console.error("User is not logged in.");
          return;
        }

        // Create a DocumentReference for the logged-in user's document in Firestore
        const tenantRef = doc(db, "users", loggedInUserId);

        // Reference to 'rents' collection with a query to filter by tenantId (DocumentReference)
        const rentCollection = collection(db, "rents");
        const userSpecificQuery = query(
          rentCollection,
          where("tenantId", "==", tenantRef)
        );

        // Fetch and map the rent data
        const rentSnapshot = await getDocs(userSpecificQuery);
        const rents = rentSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort rents by 'createdAt' field in descending order to find the latest bill
        const sortedRents = rents.sort(
          (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
        );

        // Update state with the latest bill and payment history
        if (sortedRents.length > 0) {
          setBillInsight(sortedRents[0]); // Latest bill
          setSelectedBill(sortedRents[0]);
        }
        setPaymentHistory(sortedRents); // Full payment history
      } catch (error) {
        console.error("Error fetching rent data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="w-full flex gap-3 flex-col text-black dark:text-white">
      <div className="w-full flex gap-3 smartphone:flex-col">
        <div className="w-2/5 smartphone:w-full  h-24 dark:bg-white/5 bg-black/20  p-4 rounded-lg">
          Upcoming Bills
        </div>
        <BillInsights billInsight={selectedBill || billInsight} />
      </div>

      <div className="w-full dark:bg-white/5 bg-black/20 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Payment History</h3>
        {paymentHistory.length > 0 ? (
          <ul id="paymentHistory" className="space-y-2 smartphone:space-y-1">
            <li className="text-lg text-wrap smartphone:text-xs  py-1  my-2 mb-6 border-b dark:border-white/30 border-black/30 ">
              <span>S. no</span>
              <span>Payment Date</span>
              <span>Amount</span>
              <span>Status</span>
              <span>Payment Method</span>
              <div className="w-5">View</div>
            </li>
            {paymentHistory.map((payment, index) => (
              <li
                key={payment.id}
                onClick={() => setSelectedBill(payment)}
                className={`flex text-lg smartphone:text-xs justify-between group py-2 smartphone:px-1  rounded-2xl ${
                  payment === selectedBill
                    ? "bg-firebase-accent/30 text-white border-firebase-accent"
                    : "dark:bg-white/10 bg-black/10 dark:border-white/20 border-black/20"
                }    overflow-hidden group  border duration-100 transition-all     hover:scale-[100.5%] `}
              >
                <span>{index + 1}</span>
                <span>{formatDate(payment.paymentDate).inString}</span>
                <span>${payment.amountDue}</span>
                <span>{payment.paymentStatus}</span>
                <span>{payment.paymentMethod}</span>
                <div className=" cursor-pointer ">
                  <ArrowUpRight className="w-5 group-hover:text-firebase-accent  group-hover:translate-x-[1px] group-hover:-translate-y-[1px] duration-100 transition-all" />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="w-full p-4 rounded-lg grid place-content-center">
            <Spinner className="animate-spin ease-in-out w-20 text-white" />
          </div>
        )}
      </div>
    </div>
  );
}
const BillInsights = ({ billInsight }) => {
  const [isInsightExpanded, setIsInsightExpanded] = useState(false);
  useEffect(() => {}, [billInsight]);
  if (!billInsight) {
    return (
      <div className="w-3/5 h-[310px] bg-white/5 grid place-content-center rounded-lg">
        <Spinner className="animate-spin ease-in-out w-20 text-white" />
      </div>
    );
  }

  const currentDate = new Date();
  // Helper function to assign correct styles based on payment status
  const paymentModeClasses = (paymentStatus) => {
    if (paymentStatus === "Paid") {
      return "dark:bg-green-500/5 bg-green-500/10 border border-green-500";
    } else if (
      paymentStatus === "Pending" &&
      billInsight.dueDate.toDate() >= currentDate
    ) {
      return "dark:bg-yellow-500/5 bg-yellow-500/10  border border-yellow-500";
    } else if (
      paymentStatus === "Pending" &&
      billInsight.dueDate.toDate() < currentDate
    ) {
      return "dark:bg-red-500/5 bg-red-500/10 border border-red-500";
    } else {
      return "dark:bg-white/10 bg-black/10 border border-white"; // Default styling
    }
  };
  const statusClasses = (paymentStatus) => {
    const currentDate = new Date(); // You may adjust this based on your requirements

    if (paymentStatus === "Paid") {
      return "text-green-500"; // Green text for Paid
    } else if (
      paymentStatus === "Pending" &&
      billInsight.dueDate.toDate() >= currentDate
    ) {
      return "text-yellow-500"; // Yellow text for Pending with due date not passed
    } else if (
      paymentStatus === "Pending" &&
      billInsight.dueDate.toDate() < currentDate
    ) {
      return "text-red-500"; // Red text for Pending with overdue date
    } else {
      return "text-gray-500"; // Default gray text
    }
  };
  return (
    <div
      className={`w-3/5 smartphone:w-full  p-2 rounded-lg ${
        paymentModeClasses(billInsight ? billInsight.paymentStatus : "Pending") // Default styling for null or missing data
      } dark:bg-white/5 bg-black/20 dark:text-white text-black transition-all duration-100 ease-in-out`}
    >
      <h3 className="text-lg font-semibold mb-2 ml-4">Bill Insights</h3>
      <motion.div
        initial={{ height: "310px" }}
        animate={{ height: isInsightExpanded ? "700px" : "310px" }}
        transition={{ delay: 0.5, ease: "easeInOut" }}
        style={{ transformOrigin: "end" }}
        className="overflow-hidden relative  flex flex-col items-start z-0"
      >
        <div className="w-full  flex   justify-center items-center ">
          <div
            id="insightDiv"
            className="w-1/2 smartphone:w-1/3  flex flex-col smartphone:text-xs text-xl"
          >
            <div className="w-full h-24 flex flex-col  px-4 py-2  rounded-md">
              <h1 className="w-fit  px-1 mb-4 border-b dark:border-white/20 border-black/20 ">
                Due Amount
              </h1>
              <span>${billInsight.amountDue}</span>
            </div>
            <div className="w-full h-24 flex flex-col  px-4 py-2  rounded-md">
              <h1 className="w-fit px-1 mb-4 border-b dark:border-white/20 border-black/20 ">
                Status
              </h1>

              <span className="flex items-center gap-2">
                {billInsight.paymentStatus}{" "}
                <Status
                  status={
                    billInsight.paymentStatus === "Pending" &&
                    billInsight.dueDate.toDate() < currentDate
                      ? ""
                      : billInsight.paymentStatus
                  }
                  className={`transition-all duration-100 ease-in-out ${statusClasses(
                    billInsight.paymentStatus
                  )}`}
                />
              </span>
            </div>
            <div className="w-full h-24 flex flex-col  px-4 py-2  rounded-md">
              {billInsight.paymentStatus === "Pending" && (
                <button className="w-fit px-3 py-1 dark:bg-green-700 bg-green-500 hover:scale-105 active:scale-100 rounded-lg text-base">
                  <Link to={`/payment/${billInsight.id}`}>Pay Now</Link>
                </button>
              )}
            </div>
          </div>
          <div
            id="Calendar"
            className="w-1/2 smartphone:w-2/3  flex flex-col  px-4 py-2 text-2xl  rounded-md"
          >
            <h1 className="w-fit px-1 mb-4 border-b dark:border-white/20 border-black/20  ">
              {billInsight.paymentStatus === "Paid"
                ? "Payment Date"
                : "Due Date"}
            </h1>
            <Calendar insight={billInsight} />
          </div>
        </div>
        <div className="w-full  flex justify-center items-center">
          <InsightsDetails insight={billInsight} />
        </div>
        <button
          onClick={() => {
            setIsInsightExpanded(!isInsightExpanded);
          }}
          className="absolute text-xs flex gap-1 items-center  bottom-0 right-1"
        >
          {isInsightExpanded ? "Collapse" : "Expand"}{" "}
          <DropDown
            className={`transition-all ease-in-out ${
              isInsightExpanded ? "rotate-180" : " rotate-0"
            }`}
          />
        </button>
      </motion.div>
    </div>
  );
};
const Calendar = ({ insight }) => {
  console.log(insight);
  if (!insight) return null;
  const currentDate = new Date();
  const dateType = (status) => {
    if (status === "Paid") {
      return insight.paymentDate;
    } else if (status === "Pending") {
      return insight.dueDate;
    }
  };

  const date = dateType(insight.paymentStatus);
  console.log("calender date", date);
  const jsDate = date.toDate(); // Convert Firebase Timestamp to JavaScript Date
  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

  // Calculate month details
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth();

  // Get the first day of the month and the total number of days
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  // Fill an array to represent each day in the calendar grid
  const monthDays = Array.from({ length: 42 }, (_, index) => {
    const day = index - firstDayOfMonth + 1;
    return day > 0 && day <= daysInMonth ? day : null;
  });

  // Background color based on payment status
  const dayBg = (status, dueDate) => {
    if (status === "Paid") {
      return "bg-green-500 text-white font-semibold";
    } else if (status === "Pending" && dueDate.toDate() < currentDate) {
      return "bg-red-500 text-white font-semibold";
    } else if (status === "Pending") {
      return "bg-yellow-500 text-white font-semibold";
    }
  };

  return (
    <div className="w-full p-1 dark:bg-white/10 bg-black/20 text-xs rounded-lg">
      <div className="text-center text-base font-semibold mb-3">
        {jsDate.toLocaleDateString("en-US", { month: "long", year: "numeric" })}
      </div>

      {/* Days of the week header */}
      <div className="grid grid-cols-7 gap-1 text-center font-semibold dark:text-firebase-text text-black/50">
        {daysOfWeek.map((day) => (
          <span key={day}>{day}</span>
        ))}
      </div>

      {/* Days of the month */}
      <div className="grid grid-cols-7 gap-1 mt-2">
        {monthDays.map((day, index) => (
          <div
            key={index}
            className={`h-6 flex items-center justify-center ${
              day ? "dark:text-white text-black" : "text-white dark:text-black"
            } ${
              day && day === jsDate.getDate()
                ? ` text-black rounded-full ${dayBg(
                    insight.paymentStatus,
                    insight.dueDate
                  )}`
                : ""
            }`}
          >
            {day || ""}
          </div>
        ))}
      </div>
    </div>
  );
};
const InsightsDetails = ({ insight }) => {
  const [property, setProperty] = useState(null);
  useEffect(() => {
    const fetchProperty = async () => {
      if (insight && insight.propertyId) {
        try {
          const propertyDoc = await getDoc(insight.propertyId);
          if (propertyDoc.exists()) {
            setProperty(propertyDoc.data());
          } else {
            console.error("Property not found");
          }
        } catch (error) {
          console.error("Error fetching property:", error);
        }
      }
    };

    fetchProperty();
  }, [insight]);

  if (!insight || !property) {
    return null;
  }
  const details = [
    {
      label: "Landlord Approval",
      value: insight.approved ? "Approved" : "Waiting Approval",
    },
    { label: "Payment Method", value: insight.paymentMethod },
    { label: "Transaction ID", value: insight.transactionId },
    {
      label: "Updated At",
      value: insight.updatedAt?.toDate().toLocaleString(),
    },
    { label: "Late Fee", value: `$${insight.lateFee}` },
    {
      label: "Created At",
      value: insight.createdAt?.toDate().toLocaleString(),
    },
    { label: "Property Name", value: property.name },
    { label: "Notes", value: insight.notes },
  ];

  return (
    <div className="w-full p-4">
      <h3 className="text-lg font-semibold mb-2">Additional Details</h3>
      <div className="grid grid-cols-2 gap-4">
        {details.map((detail, index) => (
          <div
            key={index}
            className="flex flex-col text-sm p-2 rounded-md dark:bg-white/5 bg-black/20"
          >
            <h1 className="font-medium text-gray-900  dark:text-gray-300">
              {detail.label}
            </h1>
            <div className="w-full h-[1px] bg-white/20 my-1" />
            <span className="dark:text-white text-black">
              {detail.value || "N/A"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
