import { useEffect, useState, useMemo } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import { auth, db } from "../../../../firebase/firebaseConfig";

import formatDate from "../../../../helpers/formatDate";
import { ArrowUpRight, Spinner } from "../../../../components/Svgs";
export default function Rents() {
  const [pendingRents, setPendingRents] = useState(null);
  const [paidRents, setPaidRents] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isAddingReceipt, setIsAddingReceipt] = useState(false);
  const [isBillExpanded, setIsBillExpanded] = useState(null);
  const user = useMemo(() => auth.currentUser, []);

  useEffect(() => {
    if (!user) return;

    const fetchRents = async () => {
      try {
        setIsLoading(true); // Start loading
        const landlordRef = doc(db, "users", user.uid); // Get landlord's document reference
        const rentsRef = collection(db, "rents");

        const rentsQuery = query(
          rentsRef,
          where("landlordId", "==", landlordRef)
        );
        const querySnapshot = await getDocs(rentsQuery);

        const rents = [];
        for (const rentDoc of querySnapshot.docs) {
          const rentData = {
            id: rentDoc.id,
            ...rentDoc.data(),
            tenantInfo: null,
          };

          // Resolve tenant reference if it exists
          if (rentData.tenantId) {
            const tenantDocRef = rentData.tenantId; // Assuming tenantId is a Firestore reference
            const tenantDoc = await getDoc(tenantDocRef);
            if (tenantDoc.exists()) {
              rentData.tenantInfo = {
                ...tenantDoc.data(),
                tenantName: tenantDoc.data().firstName,
              }; // Add tenant data to rent object
            } else {
              console.warn(
                `Tenant document ${tenantDocRef.id} does not exist.`
              );
            }
          }

          rents.push(rentData);
        }

        // Sort rents into active (pending) and past (paid) based on status
        const pending = rents.filter((rent) => rent.approved === false);
        const paid = rents.filter((rent) => rent.approved === true);

        setPendingRents(pending);
        setPaidRents(paid);
      } catch (error) {
        console.error("Error fetching rents:", error);
        toast.error("Failed to fetch rents. Please try again.");
      } finally {
        setIsLoading(false); // Stop loading
      }
    };
    fetchRents();
  }, [user, paidRents?.approved]);

  const handleBillApprove = async (id) => {
    try {
      const rentRef = doc(db, "rents", id); // Reference to the rent document
      await updateDoc(rentRef, {
        approved: true, // Assuming 'status' is a boolean field in Firestore
        paymentStatus: "Paid", // Update payment status
      });
      setPaidRents({ ...paidRents, approved: true, paymentStatus: "Paid" });
      toast.success(`Rent approved successfully.`);
    } catch (error) {
      toast.error("Failed to approve bill. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <Spinner className=" animate-spin ease-in-out mx-auto  dark:text-white text-black " />
      </div>
    );
  }

  if (!pendingRents || !paidRents) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p>Error loading data. Please refresh the page.</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="w-full min-h-screen flex flex-col gap-3"
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isAddingReceipt && (
        <AddBill setIsAddingReceipt={setIsAddingReceipt} user={user} />
      )}
      {isBillExpanded && (
        <BillDetails
          setIsBillExpanded={setIsBillExpanded}
          bill={isBillExpanded}
        />
      )}
      <button
        onClick={() => setIsAddingReceipt(!isAddingReceipt)}
        className="self-end py-2 px-5 text-white  hover:bg-firebase-accent/70 bg-firebase-accent hover:scale-[102%] active:scale-100  rounded-lg duration-100 transition-all"
      >
        Add Rent
      </button>
      {/* Pending Rents */}
      <div key="PendingRents" className="w-full">
        <div className="w-full h-fit dark:bg-white/10 bg-black/10 dark:text-white text-black px-5 py-1 mb-2 flex items-center justify-between text-[150%] rounded-xl ">
          Current Tenants
        </div>
        <ul className="w-full smartphone:justify-center flex flex-wrap gap-4 px-4">
          {pendingRents.length > 0 &&
            pendingRents.map((rent) => (
              <li
                key={rent.id}
                className="w-80 smartphone:w-full bg-black/5 dark:bg-white/20 text-black dark:text-white  p-4 rounded-md"
              >
                <div className="text-[150%]">
                  {rent.tenantInfo && (
                    <p>
                      <h1>
                        {rent.tenantInfo.firstName} {rent.tenantInfo.lastName}
                      </h1>
                      <h2 className="text-[60%]">{rent.tenantInfo.address}.</h2>
                      <h3 className="text-[60%]">
                        <span className="dark:text-gray-300 text-gray-800">
                          Last Paid On:
                        </span>{" "}
                        {formatDate(rent.dueDate).inString}
                      </h3>
                    </p>
                  )}
                </div>
                <span className="w-full place-self-end  flex justify-between mt-3 text-xs">
                  <button
                    onClick={() => setIsBillExpanded(rent)}
                    className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 "
                  >
                    Details
                  </button>
                  <button
                    onClick={() => handleBillApprove(rent.id)}
                    className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 "
                  >
                    Approve
                  </button>
                </span>
              </li>
            ))}
        </ul>
      </div>

      {/* Paid Rents */}
      <div key="PaidRents" className="w-full">
        <div className="w-full h-fit dark:bg-white/10 bg-black/10 dark:text-white text-black px-5 py-1 my-2 mt-6 flex items-center justify-between text-[150%] rounded-xl ">
          <h1>Past Tenants</h1>
        </div>
        <ul className="w-full smartphone:justify-center flex flex-wrap gap-2 px-4">
          {paidRents.length > 0 &&
            paidRents.map((rent) => (
              <li
                key={rent.id}
                className="w-80 smartphone:w-full bg-black/5 dark:bg-white/20 text-black dark:text-white  p-4 rounded-md"
              >
                <div className="text-[150%]">
                  {rent.tenantInfo && (
                    <p>
                      <h1>
                        {rent.tenantInfo.firstName} {rent.tenantInfo.lastName}
                      </h1>
                      <h2 className="text-[60%]">{rent.tenantInfo.address}.</h2>
                      <h3 className="text-[60%]">
                        <span className="dark:text-gray-300 text-gray-800">
                          Paid On:
                        </span>{" "}
                        {formatDate(rent.paymentDate).inString}
                      </h3>
                    </p>
                  )}
                </div>
                <span className="w-full place-self-end  flex justify-between mt-3 text-xs">
                  <button
                    onClick={() => setIsBillExpanded(rent)}
                    className="py-1 px-2 dark:bg-white/20  bg-black/20 dark:hover:bg-white/30 hover:bg-black/30 rounded-lg   hover:scale-110 active:scale-100 "
                  >
                    Details
                  </button>
                </span>
              </li>
            ))}
        </ul>
      </div>
    </motion.div>
  );
}

const AddBill = ({ setIsAddingReceipt, user }) => {
  const [listings, setListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [rent, setRent] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Query the listings collection where landlord.id matches landlordRef
        const listingsQuery = query(
          collection(db, "listings"),
          where("landlord.id", "==", user.uid)
        );

        // Fetch the filtered listings
        const listingsSnapshot = await getDocs(listingsQuery);

        // Map the documents into an array
        const fetchedListings = listingsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setListings(fetchedListings);
      } catch (error) {
        console.error("Error fetching listings:", error);
        toast.error("Failed to load listings.");
      }
    };

    fetchListings();
  }, [user.uid]); // Dependency on user.uid

  // Handle listing selection
  const handleListingChange = async (id) => {
    const listing = listings.find((item) => item.id === id);
    setSelectedListing(listing);
    setRent(listing?.rent || "");
    setDueDate(formatDate(listing.dueDate).fromFirebase);
    setNotes(listing?.notes || "");
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedListing) {
      toast.error("Please select a listing.");
      return;
    }

    setLoading(true);
    try {
      // Validate landlordId and tenantId before creating references
      const landlordId = selectedListing.landlord.id || "";
      const tenantId = selectedListing.tenant.id || "";

      if (!landlordId || !tenantId || !selectedListing.id) {
        toast.error(
          "Required fields are missing (landlord, tenant, or listing)."
        );
        return;
      }

      // Convert dueDate to Firebase Timestamp if provided
      const dueDateTimestamp = dueDate
        ? Timestamp.fromDate(new Date(dueDate))
        : null;

      // Create Firebase DocumentReferences for landlordId, tenantId, and listingId
      const landlordRef = doc(db, "users", landlordId); // Reference to landlord document
      const tenantRef = doc(db, "users", tenantId); // Reference to tenant document
      const listingRef = doc(db, "listings", selectedListing.id); // Reference to listing document

      // Prepare the rent record to be added to Firestore
      const rentData = {
        amountDue: rent,
        approved: false,
        dueDate: dueDateTimestamp, // Store dueDate as Firebase Timestamp
        notes,
        landlordId: landlordRef, // Store landlord as a reference
        tenantId: tenantRef, // Store tenant as a reference
        propertyId: listingRef, // Store listing as a reference
        createdAt: Timestamp.now(), // Firebase Timestamp for creation time
        paymentStatus: "Pending", //At the time of creating bill it will be pending
        updatedAt: Timestamp.now(), // Firebase Timestamp for update time
        paymentMethod: "",
        lateFee: 0,
      };

      // Add the new rent record to the "rents" collection
      const rentsRef = collection(db, "rents");
      await addDoc(rentsRef, rentData);

      toast.success("Rent record added successfully!");
      // Clear form after successful submission
      setSelectedListing(null);
      setRent("");
      setDueDate("");
      setNotes("");
    } catch (error) {
      console.error("Error adding rent record:", error);
      toast.error("Failed to add rent record.");
    } finally {
      setLoading(false);
      setIsAddingReceipt((pv) => !pv);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="w-screen h-screen z-[600]  fixed top-0 left-0 backdrop-blur bg-black/10 flex justify-center items-center"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full h-full  overflow-y-auto">
        <div className="w-full h-[10%] mb-4 flex gap-4  justify-start items-center">
          <button
            onClick={() => setIsAddingReceipt((pv) => !pv)}
            className="h-full text-black/60 hover:text-black    rounded-xl duration-100 transition-all group"
          >
            <ArrowUpRight className="w-6 rotate-[-135deg] group-hover:-translate-x-[1px] active:translate-x-0 duration-100 transition-all" />
          </button>
          <h2 className="text-2xl font-bold">Add New Rent Record</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Dropdown for selecting a listing */}
          <div>
            <label className="block mb-1">Select Listing</label>
            <select
              className="w-full px-3 py-2 border rounded-lg"
              onChange={(e) => handleListingChange(e.target.value)}
              required
            >
              <option value="">-- Select Listing --</option>
              {listings.map((listing) => (
                <option key={listing.id} value={listing.id}>
                  {listing.name}
                </option>
              ))}
            </select>
          </div>
          <AnimatePresence>
            {/* Tenant Information */}
            {selectedListing?.tenant && (
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="text-lg font-medium mb-2">Tenant Information</h3>
                <p className="text-lg">
                  <strong>Name:</strong> {selectedListing.tenant.name || "N/A"}
                </p>
                <p className="text-lg">
                  <strong>Email:</strong>{" "}
                  {selectedListing.tenant.email || "N/A"}
                </p>
                <p className="text-lg">
                  <strong>Phone:</strong>{" "}
                  {selectedListing.tenant.phone || "N/A"}
                </p>
              </div>
            )}
          </AnimatePresence>
          {/* Rent Field */}
          <div>
            <label className="block mb-1">Rent ($)</label>
            <input
              type="number"
              value={rent}
              onChange={(e) => setRent(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>

          {/* Due Date Field */}
          <div>
            <label className="block mb-1">Due Date</label>
            <input
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>

          {/* Notes Field */}
          <div>
            <label className="block mb-1">Notes</label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              rows="3"
              placeholder="Add any additional notes (optional)"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-400"
            disabled={loading}
          >
            {loading ? "Adding Rent Record..." : "Add Rent Record"}
          </button>
        </form>
      </div>
    </motion.div>
  );
};
const BillDetails = ({ bill, setIsBillExpanded }) => {
  const [tenant, setTenant] = useState(null);
  const [property, setProperty] = useState(null);
  const [landlord, setLandlord] = useState(null);

  // Fetch referenced data from Firebase
  useEffect(() => {
    const fetchReferences = async () => {
      try {
        if (bill.tenantId) {
          const tenantDoc = await getDoc(bill.tenantId);
          setTenant(tenantDoc.exists() ? tenantDoc.data() : null);
        }
        if (bill.propertyId) {
          const propertyDoc = await getDoc(bill.propertyId);
          setProperty(propertyDoc.exists() ? propertyDoc.data() : null);
        }
        if (bill.landlordId) {
          const landlordDoc = await getDoc(bill.landlordId);
          setLandlord(landlordDoc.exists() ? landlordDoc.data() : null);
        }
      } catch (error) {
        console.error("Error fetching references:", error);
      }
    };

    fetchReferences();
  }, [bill]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="w-screen h-screen fixed top-0 left-0 z-50 py-4 smartphone:py-0  backdrop-blur bg-black/20 flex justify-center items-start"
    >
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-4xl w-full h-full overflow-y-auto">
        {/* Header */}
        <div className="w-full flex items-center justify-start mb-6 select-none">
          <button
            onClick={() => setIsBillExpanded(null)}
            className="w-7 text-gray-500 hover:text-gray-800 transition duration-200 group"
          >
            <ArrowUpRight className="w-4 rotate-[-135deg] group-hover:-translate-x-[1px] active:translate-x-0 duration-100 transition-all" />
          </button>
          <h2 className="text-2xl font-bold text-gray-800">Bill Details</h2>
        </div>

        {/* Row 1: Key Details */}
        <div className="flex flex-wrap gap-6 mb-6">
          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Bill ID</h3>
            <p className="text-sm font-semibold text-gray-800">
              {bill.id || "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Status</h3>
            <p
              className={`text-sm font-semibold ${
                bill.paymentStatus === "Paid"
                  ? "text-green-600"
                  : "text-orange-500"
              }`}
            >
              {bill.paymentStatus || "Pending"}
            </p>
          </div>
        </div>

        {/* Row 2: More Details */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Amount Due</h3>
            <p className="text-sm font-semibold text-gray-800">{`$${
              bill.amountDue || "0.00"
            }`}</p>
          </div>

          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Due Date</h3>
            <p className="text-sm font-semibold text-gray-800">
              {bill.dueDate ? formatDate(bill.dueDate).inString : "N/A"}
            </p>
          </div>
        </div>

        {/* Tenant, Property, and Landlord Information */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Tenant</h3>
            <p className="text-sm font-semibold text-gray-800">
              {(tenant && `${tenant.firstName} ${tenant.lastName}`) || "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Property</h3>
            <p className="text-sm font-semibold text-gray-800">
              Address: {property?.name || "N/A"}
            </p>
            <p className="text-sm font-semibold text-gray-800">
              Address: {property?.address || "N/A"}
            </p>
          </div>

          <div className="p-4 ">
            <h3 className="text-lg font-medium text-gray-500">Landlord</h3>
            <p className="text-sm font-semibold text-gray-800">
              Name:{" "}
              {(landlord && `${landlord.firstName} ${landlord.lastName}`) ||
                "N/A"}
            </p>
            <p className="text-sm font-semibold text-gray-800">
              Email: {(landlord && `${landlord.email}`) || "N/A"}
            </p>
          </div>
        </div>

        {/* Additional Notes */}
        <div className="p-4 ">
          <h3 className="text-lg font-medium text-gray-500">Notes</h3>
          <p className="text-lg text-gray-800">
            {bill.notes || "No additional notes."}
          </p>
        </div>
      </div>
    </motion.div>
  );
};
