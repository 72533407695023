import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";
import { Spinner } from "./components/Svgs";
import { signInWithEmailAndPassword } from "firebase/auth";

const AuthContext = createContext({ isAuthenticated: false, user: null });

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Stores user object if authenticated
  const [loading, setLoading] = useState(true); // Tracks loading state while auth initializes

  // Automatically listens for authentication state changes (e.g., login, logout, refresh)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); // Firebase provides `null` if logged out
      setLoading(false); // Loading is done once auth state is determined
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  };

  const logout = async () => {
    await auth.signOut();
    setUser(null); // Reset the user state
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, login, logout, loading }}
    >
      {!loading ? (
        children
      ) : (
        <div className="dark:bg-firebase-bg bg-white/95  w-screen h-screen  grid place-content-center">
          <Spinner className="animate-spin ease-in-out  w-20 dark:text-firebase-accent text-white" />
        </div>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
