import { useEffect, useState } from "react";
import {
  collection,
  doc,
  updateDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { DropDown, Spinner } from "../../components/Svgs";
import { db } from "../../firebase/firebaseConfig";

export default function BidsPage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ticket, setTicket] = useState();
  const [bids, setBids] = useState([]);
  const [expandedSections, setExpandedsections] = useState({
    tenant: false,
    property: false,
  });
  useEffect(() => {
    const fetchTenantAndProperty = async (
      tenantRef,
      landlordRef,
      propertyRef
    ) => {
      try {
        // Fetch all data concurrently
        const [tenantSnapshot, landlordSnapshot, propertySnapshot] =
          await Promise.all([
            getDoc(tenantRef),
            getDoc(landlordRef),
            getDoc(propertyRef),
          ]);

        // Extract data from snapshots
        const tenantData = tenantSnapshot.exists()
          ? tenantSnapshot.data()
          : { firstName: "sun", lastName: "bun" };
        const landlordData = landlordSnapshot.exists()
          ? landlordSnapshot.data()
          : { firstName: "sun", lastName: "bun" };
        const propertyData = propertySnapshot.exists()
          ? propertySnapshot.data()
          : { firstName: "sun", lastName: "bun" };

        // Update ticket state with fetched info
        setTicket((prevTicket) => ({
          ...prevTicket,
          tenantInfo: tenantData || {},
          landlordInfo: landlordData || {},
          propertyInfo: propertyData || {},
        }));
      } catch (error) {
        console.error(
          "Error fetching tenant, landlord, or property info:",
          error
        );
      }
    };

    const fetchTicketAndBids = async () => {
      setIsLoading(true);
      try {
        // Fetch ticket details
        const ticketRef = doc(db, "maintenanceRequests", id);
        const ticketSnapshot = await getDoc(ticketRef);

        if (ticketSnapshot.exists()) {
          const ticketData = ticketSnapshot.data();
          setTicket({ id, ...ticketData });

          // Fetch related tenant, landlord, and property info
          const { issuedBy, issuedTo, propertyId } = ticketData;
          if (issuedBy && issuedTo && propertyId) {
            fetchTenantAndProperty(issuedBy, issuedTo, propertyId);
          }
        } else {
          console.log("No such ticket exists!");
        }

        // Fetch bids for the ticket and set up real-time updates
        const bidsRef = collection(ticketRef, "bids");
        const unsubscribe = onSnapshot(bidsRef, (snapshot) => {
          const updatedBids = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const sortedBids = updatedBids.sort(
            (a, b) => b.timestamp.toMillis() - a.timestamp.toMillis()
          );
          setBids(sortedBids);
          setIsLoading(false);
        });

        // Cleanup listener for real-time updates
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching ticket and bids:", error);
        setIsLoading(false);
      }
    };

    fetchTicketAndBids();
  }, [id]);

  const handleApproval = async (bidId) => {
    try {
      const bidRef = doc(db, `maintenanceRequests/${id}/bids`, bidId);

      // Toggle the `isApproved` field
      const bid = bids.find((bid) => bid.id === bidId);
      const updatedApprovalStatus = !bid?.isApproved;

      await updateDoc(bidRef, { isApproved: updatedApprovalStatus });

      toast.success(
        `Bid ${updatedApprovalStatus ? "approved" : "disapproved"}`
      );
    } catch (error) {
      console.error("Error updating bid approval:", error);
      toast.error("Failed to update bid approval status");
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen">
        <Spinner className="text-black dark:text-white mx-auto animate-spin" />
      </div>
    );
  }

  if (bids.length <= 0) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen text-black">
        <p>No bids found.</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="flex flex-col w-full min-h-screen overflow-y-auto bg-firebase-bg text-white"
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex justify-between w-full h-full  p-4">
        <div className="w-full h-full flex gap-3  smartphone:flex-col-reverse">
          <div
            id="ticketInfo"
            className="w-1/3 smartphone:w-full   h-full  border-r border-white/10"
          >
            <h1 className="w-full text-2xl">Ticket</h1>
            <div className="flex flex-col gap-2 px-2">
              <span className="w-full  overflow-hidden bg-white/20 rounded-lg px-2 py-1">
                <h1>Ticket Id</h1>
                <h2>{ticket.id}</h2>
              </span>
              <span className="w-full  overflow-hidden bg-white/20 rounded-lg px-2 py-1">
                <h1>Ticket Issue</h1>
                <h2>{ticket.issueType}</h2>
              </span>

              <motion.div
                initial={{ height: "35px" }}
                animate={{
                  height: expandedSections.tenant ? "35px" : "180px",
                }}
                transition={{ delay: 0.2 }}
                className="w-full h-8 overflow-hidden bg-white/20 rounded-lg px-2 py-1"
              >
                <div
                  onClick={() =>
                    setExpandedsections({
                      ...expandedSections,
                      tenant: !expandedSections.tenant,
                    })
                  }
                  className="flex justify-between items-center mb-2"
                >
                  <h1 className="text-lg">Tenant Info</h1>
                  <button>
                    <DropDown
                      className={`w-4 ${
                        !expandedSections.tenant ? "rotate-180" : "rotate-0"
                      } transition-all duration-300`}
                    />
                  </button>
                </div>
                <TenantInfo tenant={ticket.tenantInfo} />
              </motion.div>
              <motion.div
                initial={{ height: "35px" }}
                animate={{
                  height: expandedSections.property ? "35px" : "156px",
                }}
                transition={{ delay: 0.2 }}
                className="w-full h-8 overflow-hidden bg-white/20 rounded-lg px-2 py-1"
              >
                <div
                  onClick={() =>
                    setExpandedsections({
                      ...expandedSections,
                      property: !expandedSections.property,
                    })
                  }
                  className="flex justify-between items-center mb-2"
                >
                  <h1 className="text-lg">Property Info</h1>
                  <button>
                    <DropDown
                      className={`w-4 ${
                        !expandedSections.property ? "rotate-180" : "rotate-0"
                      } transition-all duration-300`}
                    />
                  </button>
                </div>
                <PropertyInfo property={ticket.propertyInfo} />
              </motion.div>
              <span className="w-full  overflow-hidden bg-white/20 rounded-lg px-2 py-1">
                <h1>Issue Description</h1>
                <h2>{ticket.issueDescription}</h2>
              </span>
            </div>
          </div>
          <div className="w-2/3 smartphone:w-full  flex gap-4  flex-col  mt-2">
            <h1 className="w-full text-2xl">Bids</h1>
            <div className="flex flex-wrap gap-3">
              {bids.map((bid) => (
                <BidCard bid={bid} handleApproval={handleApproval} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
const BidCard = ({ bid, handleApproval }) => {
  return (
    <div
      key={bid.id}
      className="smartphone:w-full flex flex-col gap-3 bg-white/10 p-4 rounded-lg border border-white/20"
    >
      <div id="bidCard" className="flex gap-4">
        <span>
          <h1>Amount</h1>
          <h2>{bid.amount}</h2>
        </span>
        <span>
          <h1>Contractor</h1>
          <h2>{bid.contractorName || "Contractor"}</h2>
        </span>
      </div>
      <div>
        <h1>Bid Status</h1>
        <h2>{bid.isApproved ? "Locked" : "Open"}</h2>
      </div>
      <div>
        <button
          onClick={() => handleApproval(bid.id)}
          className={`w-full text-center py-2 bg-white text-black rounded border ${
            bid.isApproved ? "border-red-600 bg-red-100" : "border-green-600"
          }`}
        >
          {!bid.isApproved ? "Approve" : "Disapprove"}
        </button>
      </div>
    </div>
  );
};
const TenantInfo = ({ tenant }) => {
  if (!tenant) {
    return <div>No tenant information available.</div>;
  }

  return (
    <div className="p-4 border border-gray-200 rounded shadow-md bg-white text-black">
      <p>
        <strong>Name:</strong>{" "}
        {tenant.firstName + " " + tenant.lastName || "N/A"}
      </p>
      <p>
        <strong>Email:</strong> {tenant.email || "N/A"}
      </p>
      <p>
        <strong>Address:</strong> {tenant.address || "N/A"}
      </p>
      <p>
        <strong>Phone:</strong> {tenant.phone || "N/A"}
      </p>
    </div>
  );
};

const PropertyInfo = ({ property }) => {
  if (!property) {
    return <div>No property information available.</div>;
  }

  return (
    <div className="p-4 border border-gray-200 rounded shadow-md bg-white text-black">
      <p>
        <strong>Address:</strong> {property.address || "N/A"}
      </p>
      <p>
        <strong>Rent:</strong> {property.rent ? `$${property.rent}` : "N/A"}
      </p>
      <p>
        <strong>Name:</strong> {property.name || "N/A"}
      </p>
    </div>
  );
};
